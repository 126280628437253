import {
  endOfMonth,
  endOfYear,
  format,
  startOfMonth,
  startOfYear
} from 'date-fns'

export const paymentType = (dictionary, setQueries, payment_type = false) => [
  {
    value: '',
    label: dictionary.all_payment_types,
    handleClick: () =>
      setQueries(prev => {
        if (payment_type) delete prev.payment_type
        else delete prev.type

        return { ...prev }
      })
  },
  {
    value: 'cash',
    // value: 'E',
    label: dictionary.cash,
    handleClick: () =>
      setQueries(prev => ({
        ...prev,
        [payment_type ? 'payment_type' : 'type']: 'cash'
      }))
  },
  {
    label: dictionary.check,
    value: 'check',
    // value: 'C',
    handleClick: () =>
      setQueries(prev => ({
        ...prev,
        [payment_type ? 'payment_type' : 'type']: 'check'
      }))
  },
  {
    label: dictionary.card,
    value: 'card',
    // value: 'U',
    handleClick: () =>
      setQueries(prev => ({
        ...prev,
        [payment_type ? 'payment_type' : 'type']: 'card'
      }))
  },
  {
    label: dictionary.lcr,
    value: 'lcr',
    // value: 'L',
    handleClick: () =>
      setQueries(prev => ({
        ...prev,
        [payment_type ? 'payment_type' : 'type']: 'lcr'
      }))
  },
  {
    label: dictionary.transfer,
    value: 'transfer',
    handleClick: () =>
      setQueries(prev => ({
        ...prev,
        [payment_type ? 'payment_type' : 'type']: 'transfer'
      }))
  },
  {
    label: dictionary.deposit,
    value: 'deposit',
    // value: 'P',
    handleClick: () =>
      setQueries(prev => ({
        ...prev,
        [payment_type ? 'payment_type' : 'type']: 'deposit'
      }))
  },
  {
    label: dictionary.none,
    value: '-',
    // value: 'S',
    handleClick: () =>
      setQueries(prev => ({
        ...prev,
        [payment_type ? 'payment_type' : 'type']: '-'
      }))
  }
]

export const validStatus = (dictionary, setQueries) => [
  {
    value: '',
    label: dictionary.all_statuses,
    handleClick: () =>
      setQueries &&
      setQueries(prev => {
        delete prev.is_validated
        return { ...prev }
      })
  },
  {
    value: 'Y',
    label: dictionary.valid,
    handleClick: () =>
      setQueries && setQueries(prev => ({ ...prev, is_validated: 'Y' }))
  },
  {
    value: 'N',
    label: dictionary.not_valid,
    handleClick: () =>
      setQueries && setQueries(prev => ({ ...prev, is_validated: 'N' }))
  }
]

export const isRecuperation = (
  dictionary,
  queries,
  setQueries,
  isPagination = false
) => [
  {
    value: '',
    label: `${dictionary.recuperation}?`,
    handleClick: () => {
      if (setQueries && !isPagination) {
        setQueries(prev => {
          delete prev.is_recuperation
          return { ...prev }
        })
      }
      if (setQueries && isPagination) {
        const newQuery = { ...queries }
        delete newQuery.is_recuperation
        return setQueries({ ...newQuery }, true)
      }
    }
  },
  {
    value: 1,
    label: dictionary.yes,
    handleClick: () => {
      if (setQueries && !isPagination) {
        return setQueries(prev => ({
          ...prev,
          is_recuperation: true
        }))
      }
      if (setQueries && isPagination) {
        return setQueries({ ...queries, is_recuperation: true })
      }
    }
  },
  {
    value: 2,
    label: dictionary.no,
    handleClick: () => {
      if (setQueries && !isPagination) {
        return setQueries(prev => ({
          ...prev,
          is_recuperation: false
        }))
      }
      if (setQueries && isPagination) {
        return setQueries({ ...queries, is_recuperation: false })
      }
    }
  }
]

export const costStatus = (dictionary, setQueries) => [
  {
    value: '',
    label: dictionary.any_status,
    handleClick: () =>
      setQueries &&
      setQueries(prev => {
        delete prev.status
        return { ...prev }
      })
  },
  {
    value: 'not_reviewed',
    label: dictionary.not_reviewed,
    handleClick: () => setQueries(prev => ({ ...prev, status: 'not_reviewed' }))
  },
  {
    value: 'not_reimbursed_yet',
    label: dictionary.not_reimbursed_yet,
    handleClick: () =>
      setQueries(prev => ({ ...prev, status: 'not_reimbursed_yet' }))
  },

  {
    value: 'reimbursed',
    label: dictionary.reimbursed,
    handleClick: () => setQueries(prev => ({ ...prev, status: 'reimbursed' }))
  },

  {
    value: 'billable',
    label: dictionary.billable,
    handleClick: () => setQueries(prev => ({ ...prev, status: 'billable' }))
  },
  {
    value: 'billed',
    label: dictionary.billed,
    handleClick: () => setQueries(prev => ({ ...prev, status: 'billed' }))
  }
]

export const isImported = (dictionary, setQueries) => [
  {
    value: '',
    label: `${dictionary.imported}?`,
    handleClick: () =>
      setQueries && setQueries(prev => ({ ...prev, is_imported: '', page: 1 }))
  },
  {
    value: 1,
    label: dictionary.yes,
    handleClick: () =>
      setQueries &&
      setQueries(prev => ({
        ...prev,
        is_imported: true,
        page: 1
      }))
  },
  {
    value: 2,
    label: dictionary.no,
    handleClick: () =>
      setQueries &&
      setQueries(prev => ({
        ...prev,
        is_imported: false,
        page: 1
      }))
  }
]

export const projectStatus = (dictionary, setQueries) => [
  {
    value: '',
    label: dictionary.all_statuses,
    handleClick: () =>
      setQueries &&
      setQueries(prev => {
        delete prev.status
        return { ...prev, page: 1 }
      })
  },
  {
    value: 'signed',
    label: dictionary.signed,
    handleClick: () =>
      setQueries &&
      setQueries(prev => ({
        ...prev,
        status: 'signed',
        page: 1
      }))
  },
  {
    value: 'hold',
    label: dictionary.on_hold,
    handleClick: () =>
      setQueries &&
      setQueries(prev => ({
        ...prev,
        status: 'hold',
        page: 1
      }))
  },
  {
    value: 'completed',
    label: dictionary.completed,
    handleClick: () =>
      setQueries &&
      setQueries(prev => ({
        ...prev,
        status: 'completed',
        page: 1
      }))
  },
  {
    value: 'paid',
    label: dictionary.paid,
    handleClick: () =>
      setQueries &&
      setQueries(prev => ({
        ...prev,
        status: 'paid',
        page: 1
      }))
  },
  {
    value: 'litigious',
    label: dictionary.litigious,
    handleClick: () =>
      setQueries &&
      setQueries(prev => ({
        ...prev,
        status: 'litigious',
        page: 1
      }))
  },
  {
    value: 'refused',
    label: dictionary.refused,
    handleClick: () =>
      setQueries &&
      setQueries(prev => ({
        ...prev,
        status: 'refused',
        page: 1
      }))
  }
]

export const dateFilter = (
  dictionary,
  setQueries,
  isPagination,
  queries,
  finance
) => {
  const start_date = finance ? 'period_start' : 'start_date'
  const end_date = finance ? 'period_end' : 'end_date'

  return [
    {
      value: '',
      label: '----------',
      handleClick: () => {
        if (setQueries && !isPagination) {
          return setQueries(prev => {
            delete prev[start_date]
            delete prev[end_date]
            return { ...prev, page: 1 }
          })
        }
        if (setQueries && isPagination) {
          const newQuery = { ...queries }
          delete newQuery[start_date]
          delete newQuery[end_date]
          return setQueries({ ...newQuery }, true)
        }
      }
    },
    {
      value: 'today',
      label: dictionary.today,
      handleClick: () => {
        if (setQueries && !isPagination) {
          return setQueries(prev => ({
            ...prev,
            page: 1,
            [start_date]: format(new Date(), 'yyyy-MM-dd'),
            [end_date]: format(new Date(), 'yyyy-MM-dd')
          }))
        }
        if (setQueries && isPagination) {
          return setQueries({
            ...queries,
            [start_date]: format(new Date(), 'yyyy-MM-dd'),
            [end_date]: format(new Date(), 'yyyy-MM-dd')
          })
        }
      }
    },
    {
      value: 'last_7_days',
      label: dictionary.last_7_days,
      handleClick: () => {
        if (setQueries && !isPagination) {
          const d = new Date()
          d.setDate(d.getDate() - 6)

          setQueries(prev => ({
            ...prev,
            page: 1,
            [start_date]: format(d, 'yyyy-MM-dd'),
            [end_date]: format(new Date(), 'yyyy-MM-dd')
          }))
        }
        if (setQueries && isPagination) {
          const d = new Date()
          d.setDate(d.getDate() - 6)

          return setQueries({
            ...queries,
            [start_date]: format(d, 'yyyy-MM-dd'),
            [end_date]: format(new Date(), 'yyyy-MM-dd')
          })
        }
      }
    },
    {
      value: 'last_30_days',
      label: dictionary.last_30_days,
      handleClick: () => {
        if (setQueries && !isPagination) {
          const d = new Date()
          d.setDate(d.getDate() - 29)

          setQueries(prev => ({
            ...prev,
            page: 1,
            [start_date]: format(d, 'yyyy-MM-dd'),
            [end_date]: format(new Date(), 'yyyy-MM-dd')
          }))
        }
        if (setQueries && isPagination) {
          const d = new Date()
          d.setDate(d.getDate() - 29)

          return setQueries({
            ...queries,
            [start_date]: format(d, 'yyyy-MM-dd'),
            [end_date]: format(new Date(), 'yyyy-MM-dd')
          })
        }
      }
    },
    {
      value: 'last_365_days',
      label: dictionary.last_365_days,
      handleClick: () => {
        if (setQueries && !isPagination) {
          const d = new Date()
          d.setDate(d.getDate() - 364)

          setQueries(prev => ({
            ...prev,
            page: 1,
            [start_date]: format(d, 'yyyy-MM-dd'),
            [end_date]: format(new Date(), 'yyyy-MM-dd')
          }))
        }
        if (setQueries && isPagination) {
          const d = new Date()
          d.setDate(d.getDate() - 364)

          return setQueries({
            ...queries,
            [start_date]: format(d, 'yyyy-MM-dd'),
            [end_date]: format(new Date(), 'yyyy-MM-dd')
          })
        }
      }
    },
    {
      value: 'this_month',
      label: dictionary.this_month,
      handleClick: () => {
        if (setQueries && !isPagination) {
          const d = new Date()

          setQueries(prev => ({
            ...prev,
            page: 1,
            [start_date]: format(startOfMonth(d), 'yyyy-MM-dd'),
            [end_date]: format(endOfMonth(d), 'yyyy-MM-dd')
          }))
        }
        if (setQueries && isPagination) {
          const d = new Date()

          return setQueries({
            ...queries,
            [start_date]: format(startOfMonth(d), 'yyyy-MM-dd'),
            [end_date]: format(endOfMonth(d), 'yyyy-MM-dd')
          })
        }
      }
    },
    {
      value: 'last_month',
      label: dictionary.last_month,
      handleClick: () => {
        if (setQueries && !isPagination) {
          const d = new Date()
          d.setDate(0)

          setQueries(prev => ({
            ...prev,
            page: 1,
            [start_date]: format(startOfMonth(d), 'yyyy-MM-dd'),
            [end_date]: format(endOfMonth(d), 'yyyy-MM-dd')
          }))
        }
        if (setQueries && isPagination) {
          const d = new Date()
          d.setDate(0)

          return setQueries({
            ...queries,
            [start_date]: format(startOfMonth(d), 'yyyy-MM-dd'),
            [end_date]: format(endOfMonth(d), 'yyyy-MM-dd')
          })
        }
      }
    },
    {
      value: 'this_year',
      label: dictionary.this_year,
      handleClick: () => {
        if (setQueries && !isPagination) {
          const d = new Date()
          d.setFullYear(d.getFullYear())
          setQueries(prev => ({
            ...prev,
            page: 1,
            [start_date]: format(startOfYear(d), 'yyyy-MM-dd'),
            [end_date]: format(endOfYear(d), 'yyyy-MM-dd')
          }))
        }
        if (setQueries && isPagination) {
          const d = new Date()
          return setQueries({
            ...queries,
            [start_date]: format(startOfYear(d), 'yyyy-MM-dd'),
            [end_date]: format(endOfYear(d), 'yyyy-MM-dd')
          })
        }
      }
    },
    {
      value: 'last_year',
      label: dictionary.last_year,
      handleClick: () => {
        if (setQueries && !isPagination) {
          const d = new Date()
          d.setFullYear(d.getFullYear() - 1)
          setQueries(prev => ({
            ...prev,
            page: 1,
            [start_date]: format(startOfYear(d), 'yyyy-MM-dd'),
            [end_date]: format(endOfYear(d), 'yyyy-MM-dd')
          }))
        }
        if (setQueries && isPagination) {
          const d = new Date()
          d.setFullYear(d.getFullYear() - 1)

          return setQueries({
            ...queries,
            [start_date]: format(startOfMonth(d), 'yyyy-MM-dd'),
            [end_date]: format(endOfMonth(d), 'yyyy-MM-dd')
          })
        }
      }
    },
    {
      value: 'custom_dates',
      label: dictionary.custom_dates,
      handleClick: () => {
        if (setQueries && !isPagination) {
          const d = new Date()
          d.setFullYear(d.getFullYear() - 1)
          setQueries(prev => ({
            ...prev,
            page: 1,
            [start_date]: format(startOfYear(d), 'yyyy-MM-dd'),
            [end_date]: format(endOfYear(d), 'yyyy-MM-dd')
          }))
        }
        if (setQueries && isPagination) {
          const d = new Date()
          d.setFullYear(d.getFullYear() - 1)

          return setQueries({
            ...queries,
            [start_date]: format(startOfMonth(d), 'yyyy-MM-dd'),
            [end_date]: format(endOfMonth(d), 'yyyy-MM-dd')
          })
        }
      }
    }
  ]
}

export const invoiceDateFilter = (
  dictionary,
  setQueries,
  isPagination,
  queries
) => [
  {
    value: '',
    label: '----------',
    handleClick: () => {
      if (setQueries && !isPagination) {
        return setQueries(prev => {
          delete prev.invoice_date_start
          delete prev.invoice_date_end
          return { ...prev, page: 1 }
        })
      }
      if (setQueries && isPagination) {
        const newQuery = { ...queries }
        delete newQuery.invoice_date_start
        delete newQuery.invoice_date_end
        return setQueries({ ...newQuery }, true)
      }
    }
  },
  {
    value: 'today',
    label: dictionary.today,
    handleClick: () => {
      if (setQueries && !isPagination) {
        return setQueries(prev => ({
          ...prev,
          page: 1,
          invoice_date_start: format(new Date(), 'dd-MM-yyyy'),
          invoice_date_end: format(new Date(), 'dd-MM-yyyy')
        }))
      }
      if (setQueries && isPagination) {
        return setQueries({
          ...queries,
          invoice_date_start: format(new Date(), 'dd-MM-yyyy'),
          invoice_date_end: format(new Date(), 'dd-MM-yyyy')
        })
      }
    }
  },
  {
    value: 'last_7_days',
    label: dictionary.last_7_days,
    handleClick: () => {
      if (setQueries && !isPagination) {
        const d = new Date()
        d.setDate(d.getDate() - 6)

        setQueries(prev => ({
          ...prev,
          page: 1,
          invoice_date_start: format(d, 'dd-MM-yyyy'),
          invoice_date_end: format(new Date(), 'dd-MM-yyyy')
        }))
      }
      if (setQueries && isPagination) {
        const d = new Date()
        d.setDate(d.getDate() - 6)

        return setQueries({
          ...queries,
          invoice_date_start: format(d, 'dd-MM-yyyy'),
          invoice_date_end: format(new Date(), 'dd-MM-yyyy')
        })
      }
    }
  },
  {
    value: 'last_30_days',
    label: dictionary.last_30_days,
    handleClick: () => {
      if (setQueries && !isPagination) {
        const d = new Date()
        d.setDate(d.getDate() - 29)

        setQueries(prev => ({
          ...prev,
          page: 1,
          invoice_date_start: format(d, 'dd-MM-yyyy'),
          invoice_date_end: format(new Date(), 'dd-MM-yyyy')
        }))
      }
      if (setQueries && isPagination) {
        const d = new Date()
        d.setDate(d.getDate() - 29)

        return setQueries({
          ...queries,
          invoice_date_start: format(d, 'dd-MM-yyyy'),
          invoice_date_end: format(new Date(), 'dd-MM-yyyy')
        })
      }
    }
  },
  {
    value: 'last_365_days',
    label: dictionary.last_365_days,
    handleClick: () => {
      if (setQueries && !isPagination) {
        const d = new Date()
        d.setDate(d.getDate() - 364)

        setQueries(prev => ({
          ...prev,
          page: 1,
          invoice_date_start: format(d, 'dd-MM-yyyy'),
          invoice_date_end: format(new Date(), 'dd-MM-yyyy')
        }))
      }
      if (setQueries && isPagination) {
        const d = new Date()
        d.setDate(d.getDate() - 364)

        return setQueries({
          ...queries,
          invoice_date_start: format(d, 'dd-MM-yyyy'),
          invoice_date_end: format(new Date(), 'dd-MM-yyyy')
        })
      }
    }
  },
  {
    value: 'this_month',
    label: dictionary.this_month,
    handleClick: () => {
      if (setQueries && !isPagination) {
        const d = new Date()

        setQueries(prev => ({
          ...prev,
          page: 1,
          invoice_date_start: format(startOfMonth(d), 'dd-MM-yyyy'),
          invoice_date_end: format(endOfMonth(d), 'dd-MM-yyyy')
        }))
      }
      if (setQueries && isPagination) {
        const d = new Date()

        return setQueries({
          ...queries,
          invoice_date_start: format(startOfMonth(d), 'dd-MM-yyyy'),
          invoice_date_end: format(endOfMonth(d), 'dd-MM-yyyy')
        })
      }
    }
  },
  {
    value: 'last_month',
    label: dictionary.last_month,
    handleClick: () => {
      if (setQueries && !isPagination) {
        const d = new Date()
        d.setDate(0)

        setQueries(prev => ({
          ...prev,
          page: 1,
          invoice_date_start: format(startOfMonth(d), 'dd-MM-yyyy'),
          invoice_date_end: format(endOfMonth(d), 'dd-MM-yyyy')
        }))
      }
      if (setQueries && isPagination) {
        const d = new Date()
        d.setDate(0)

        return setQueries({
          ...queries,
          invoice_date_start: format(startOfMonth(d), 'dd-MM-yyyy'),
          invoice_date_end: format(endOfMonth(d), 'dd-MM-yyyy')
        })
      }
    }
  },
  {
    value: 'this_year',
    label: dictionary.this_year,
    handleClick: () => {
      if (setQueries && !isPagination) {
        const d = new Date()
        d.setFullYear(d.getFullYear())
        setQueries(prev => ({
          ...prev,
          page: 1,
          invoice_date_start: format(startOfYear(d), 'dd-MM-yyyy'),
          invoice_date_end: format(endOfYear(d), 'dd-MM-yyyy')
        }))
      }
      if (setQueries && isPagination) {
        const d = new Date()
        return setQueries({
          ...queries,
          invoice_date_start: format(startOfYear(d), 'dd-MM-yyyy'),
          invoice_date_end: format(endOfYear(d), 'dd-MM-yyyy')
        })
      }
    }
  },
  {
    value: 'last_year',
    label: dictionary.last_year,
    handleClick: () => {
      if (setQueries && !isPagination) {
        const d = new Date()
        d.setFullYear(d.getFullYear() - 1)
        setQueries(prev => ({
          ...prev,
          page: 1,
          invoice_date_start: format(startOfYear(d), 'dd-MM-yyyy'),
          invoice_date_end: format(endOfYear(d), 'dd-MM-yyyy')
        }))
      }
      if (setQueries && isPagination) {
        const d = new Date()
        d.setFullYear(d.getFullYear() - 1)

        return setQueries({
          ...queries,
          invoice_date_start: format(startOfMonth(d), 'dd-MM-yyyy'),
          invoice_date_end: format(endOfMonth(d), 'dd-MM-yyyy')
        })
      }
    }
  },
  {
    value: 'custom_dates',
    label: dictionary.custom_dates,
    handleClick: () => {
      if (setQueries && !isPagination) {
        const d = new Date()
        d.setFullYear(d.getFullYear() - 1)
        setQueries(prev => ({
          ...prev,
          page: 1,
          invoice_date_start: format(startOfYear(d), 'dd-MM-yyyy'),
          invoice_date_end: format(endOfYear(d), 'dd-MM-yyyy')
        }))
      }
      if (setQueries && isPagination) {
        const d = new Date()
        d.setFullYear(d.getFullYear() - 1)

        return setQueries({
          ...queries,
          invoice_date_start: format(startOfMonth(d), 'dd-MM-yyyy'),
          invoice_date_end: format(endOfMonth(d), 'dd-MM-yyyy')
        })
      }
    }
  }
]

export const invoiceOpenStatus = (
  dic,
  setQueries,
  SetFilterQuery,
  isPagination
) => [
  {
    value: ['not_paid', 'partial', 'late', 'litigious'],
    label: dic.all_statuses,
    handleClick: () =>
      isPagination
        ? SetFilterQuery({
            status: ['not_paid', 'partial', 'late', 'litigious']
          })
        : setQueries(prev => ({
            ...prev,
            status: ['not_paid', 'partial', 'late', 'litigious']
          }))
  },
  {
    value: 'not_paid',
    label: dic.not_paid,
    handleClick: () =>
      isPagination
        ? SetFilterQuery({ status: ['not_paid'] })
        : setQueries(prev => ({ ...prev, status: ['not_paid'] }))
  },
  {
    value: 'partial',
    label: dic.partial,
    handleClick: () =>
      isPagination
        ? SetFilterQuery({ status: ['partial'] })
        : setQueries(prev => ({ ...prev, status: ['partial'] }))
  },
  {
    value: 'late',
    label: dic.late,
    handleClick: () =>
      isPagination
        ? SetFilterQuery({ status: ['late'] })
        : setQueries(prev => ({ ...prev, status: ['late'] }))
  },
  {
    value: 'litigious',
    label: dic.litigious,
    handleClick: () =>
      isPagination
        ? SetFilterQuery({ status: ['litigious'] })
        : setQueries(prev => ({ ...prev, status: ['litigious'] }))
  }
]

export default function numberWithCommas(number = 0, precision = 0, unit = '') {
  number = number || 0
  const parsedUnit = unit ? (unit == '%' ? unit : `\u00A0${unit}`) : ''
  let n = number
  let newNumber = Number(number.toString().replace(',', '.'))
  if (isNaN(newNumber)) {
    return Number(0).toFixed(precision)
  }
  n = Number.parseFloat(newNumber).toFixed(precision)
  // const parsedNumber = n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\u00A0') // Unicode U+00A0 => non breaking space
  const numberString = n.toString()
  let formattedNumber = ''
  let count = 0
  for (let i = numberString.length - 1; i >= 0; i--) {
    if (numberString[i] === '.') {
      formattedNumber = '.' + formattedNumber
      count = 0 // Reset the count after encountering a decimal point
    } else {
      formattedNumber = numberString[i] + formattedNumber
      count++
      if (count % 3 === 0 && i !== 0) {
        formattedNumber = '\u00A0' + formattedNumber
      }
    }
  }
  const parsedNumber = formattedNumber

  return parsedNumber + parsedUnit
}

import { useState } from 'react'
import PropTypes from 'prop-types'
import {
  IconButton,
  Popover,
  Typography,
  Badge,
  Divider,
  Grid,
  MenuItem,
  Tooltip,
  Stack,
  Button,
  ListSubheader
} from '@mui/material'

import { format } from '@utils/date-fns_functions'

import {
  Close as CloseIcon,
  FilterList,
  RestartAlt as RestartAltIcon
} from '@mui/icons-material'
import DatePicker from '@components/form/DatePicker'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { useForm } from 'react-hook-form'

import { useTranslation } from '@contexts/translation'

import useStyles from '@components/common/Filter/styles'
import { useAuth } from '@contexts/auth'
import { useFetchData } from '@api/fetchData'
import API_URL from '@config/services'
import Select from '../SelectUncontrolled'
import {
  costStatus,
  isRecuperation,
  paymentType,
  validStatus,
  isImported,
  dateFilter,
  projectStatus,
  invoiceOpenStatus,
  invoiceDateFilter
} from './filters_types'
import queryString from '@utils/queryString'
import { useRouter } from 'next/router'

const GeneralFilter = ({
  disabled = false,
  filterActivated = 0,
  queries,
  setQueries,
  initialQueries = {},
  clearButton = true,
  project = false,
  orguser = false,
  contractor = false,
  is_paid = false,
  payment_type = false,
  timelog_type = false,
  valid_status = false,
  is_recuperation = false,
  cost_status = false,
  role = false,
  tag = false,
  project_tags = false,
  sync_with_budget = false,
  sync_with_planning = false,
  phase = false,
  annex = false,
  cities = false,
  currencies = false,
  is_open = false,
  type = false,
  // clients = false,
  isPagination = false,
  is_internal = false,
  is_imported = false,
  user_state = false,
  user_status = false,
  tags = false,
  projectId = null,
  client_currency = false,
  payment_type_different = false,
  date = false,
  dateRequestedOn = false,
  stage,
  project_status,
  billable,
  reimbursable,
  expenseCategories,
  expenseStatus,
  job_status,
  invoice_open_status,
  cities_projects = null,
  cities_opportunities = null,
  suppliers = false,
  //years = [],
  SetFilterQuery = () => {},
  invoice = false,
  lease = false,
  building = false,
  floor = false,
  active = false,
  mortgageType = false,
  finance = false,
  priority = false,
  status = false,
  occupancy = false,
  accounts = false,
  accountsList = [],
  tenant = false,
  landlord = false,
  task_list = false,
  tasks_status = false,
  tasks_states = false,
  currentTab,
  height = null
}) => {
  const { organization, organizationTeam } = useAuth()
  const [openDatePicker, setOpenDatePicker] = useState(false)
  const router = useRouter()

  const classes = useStyles()
  const { dictionary, t } = useTranslation()
  const schema = yup.object().shape({
    start_date: yup.date().nullable().required(dictionary.required),
    end_date: yup
      .date()
      .nullable()
      .required(dictionary.required)
      .min(yup.ref('start_date'), dictionary.end_date_after_start)
  })
  const {
    watch,
    formState: { errors },
    control,
    handleSubmit
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      start_date: null,
      end_date: null
    }
  })
  const TYPE_LIST = [
    { label: dictionary.permanent, value: 'permanent' },
    { label: dictionary.permanent_mission, value: 'permanent_mission' },
    {
      label: dictionary.permanent_construction,
      value: 'permanent_construction'
    },
    { label: dictionary.fixed_term, value: 'fixed_term' },
    { label: dictionary.interim, value: 'temporary' },
    { label: dictionary.apprenticeship, value: 'apprenticeship' },
    { label: dictionary.professionalization, value: 'professionalization' },
    { label: dictionary.freelancer, value: 'freelance' },
    { label: dictionary.service_delivery, value: 'service delivery' },
    { label: dictionary.intern, value: 'intern' },
    { label: dictionary.bonus, value: 'bonus' }
  ]

  const [anchorEl, setAnchorEl] = useState(null)
  const [dateValue, setDateValue] = useState('')

  /* fetch data that needs to be fetched */
  const { data: _projectsList } = useFetchData(
    project ? API_URL.PROJECTS.GET_PROJECT_LIST_PK : null
  )
  // will not be fetched (NULL) if project === false

  const { data: _orgusersList } = useFetchData(
    orguser
      ? API_URL.ORG_USER.GET_ORG_USER_PK(organization) +
          '?' +
          queryString({
            team: organizationTeam,
            page_size: 999,
            all_users: true
          })
      : null
  )

  const { data: _contractorsList } = useFetchData(
    contractor
      ? API_URL.CONTRACTS.GET_CONTRACTS_CONTRACTOR_LIST(organization) +
          '?' +
          queryString({ team: organizationTeam })
      : null
  )

  const { data: _typesList } = useFetchData(
    timelog_type
      ? API_URL.TIMELOGS.GET_TIMELOGS_TYPES_LIST(organization) +
          '?' +
          queryString({ is_active: true })
      : null
  )

  const { data: _rolesList } = useFetchData(
    role
      ? API_URL.ROLES.ALL_ROLES(organization) +
          '?' +
          queryString({ team: organizationTeam })
      : null
  )

  const { data: _tagsList } = useFetchData(
    tag || project_tags
      ? API_URL.PROJECTS.GET_PROJECT_TAGS_LIST(organization)
      : null
  )

  /* const { data: _userTags } = useFetchData(
    tags
      ? API_URL.TAGS.GET_TAGS_LIST(organization) +
          `?type=${contractors_tags ? 'contractors' : 'orgusers'}`
      : null
  ) */

  let _userTags

  const { data: _currenciesList } = useFetchData(
    currencies || client_currency
      ? API_URL.CURRENCIES.GET_CURRENCIES_LIST
      : null
  )

  const fetchListsURL = task_list ? API_URL.TASKS.GET_TASKS_LISTS_LIST() : null
  // const fetchLabelsURL = API_URL.TASKS.GET_TASKS_LABEL_LIST(organization)
  const { data: _taskLists } = useFetchData(fetchListsURL)
  const { data: _phasesList } = useFetchData(
    phase
      ? API_URL.PHASES.GET_PHASES_PK(initialQueries?.project || projectId)
      : null
  )

  const { data: _citiesList } = useFetchData(
    cities ? API_URL.CITIES.GET_CITIES : null
  )

  const { data: _annexesList } = useFetchData(
    annex
      ? API_URL.ANNEXES.GET_ANNEXES_LIST(initialQueries?.project || projectId)
      : null
  )

  const { data: _stagesList } = useFetchData(
    stage
      ? API_URL.PIPELINES.GET_PIPELINES_STAGE_LIST(organization) +
          '?' +
          queryString({
            signed: false,
            team: organizationTeam,
            status: 'open'
          })
      : null
  )

  const { data: categories } = useFetchData(
    expenseCategories
      ? API_URL.CATEGORIES.GET_CATEGORIES() +
          '?' +
          queryString(
            projectId
              ? {
                  is_valid: true,
                  operation: projectId
                }
              : {
                  is_valid: true
                }
          )
      : null
  )

  const { data: expenseSuppliers } = useFetchData(
    suppliers
      ? API_URL.SUPPLIERS.GET_SUPPLIERS_LIST() +
          '?page_size=999&' +
          queryString(
            projectId
              ? {
                  operation: projectId
                }
              : {}
          )
      : null
  )

  const { data: _leaseList } = useFetchData(
    lease
      ? API_URL.LEASES.GET_LEASES_LIST(initialQueries?.operation || '')
      : null
  )

  const leaseList =
    _leaseList?.results.map(lease => ({
      value: lease.id,
      label: lease.display,
      handleClick: () =>
        isPagination
          ? SetFilterQuery({ lease: lease.id })
          : setQueries(prev => ({ ...prev, lease: lease.id }))
    })) || []

  const { data: _buildingsList } = useFetchData(
    building
      ? API_URL.BUILDINGS.GET_BUILDINGS() +
          '?' +
          queryString({ operation: projectId })
      : null
  )

  const buildingsList =
    _buildingsList?.results.map(b => ({
      value: b.id,
      label: b.name,
      handleClick: () =>
        isPagination
          ? SetFilterQuery({ building: b.id })
          : setQueries(prev => ({ ...prev, building: b.id }))
    })) || []

  const { data: _floorsList } = useFetchData(
    floor
      ? queries.building
        ? API_URL.FLOORS.GET_FLOORS() +
          '?' +
          queryString({ operation: projectId, building: queries.building })
        : API_URL.FLOORS.GET_FLOORS() +
          '?' +
          queryString({ operationId: projectId })
      : null
  )

  const floorsList =
    _floorsList?.results.map(f => ({
      value: f.id,
      label: f.name,
      handleClick: () =>
        isPagination
          ? SetFilterQuery({ floor: f.id })
          : setQueries(prev => ({ ...prev, floor: f.id }))
    })) || []

  const { data: _tenantsList } = useFetchData(
    tenant
      ? API_URL.TENANTS.GET_TENANTS_LIST() +
          '?page_size=999&operation=' +
          (initialQueries?.operation ? initialQueries?.operation : '')
      : null
  )

  const tenantsList = _tenantsList?.results.map(t => ({
    value: t.id,
    label: t.display,
    handleClick: () =>
      isPagination
        ? SetFilterQuery({ tenant: t.id })
        : setQueries(prev => ({ ...prev, operation: t.id }))
  }))

  const { data: _landlordsList } = useFetchData(
    landlord ? API_URL.LANDLORDS.GET_LANDLORDS_LIST() : null
  )

  const landlordsList = _landlordsList?.results.map(l => ({
    value: l.id,
    label: l.display,
    handleClick: () =>
      isPagination
        ? SetFilterQuery({ landlord: l.id })
        : setQueries(prev => ({ ...prev, landlord: l.id }))
  }))

  /* format data as: [{value, label, handleClick}, {value, label, handleClick}, ...] */
  const projectsList =
    _projectsList?.results.map(p => ({
      value: p.id,
      label: p.display,
      handleClick: () =>
        isPagination
          ? SetFilterQuery({ operation: p.id })
          : setQueries(prev => ({ ...prev, operation: p.id }))
    })) || []

  const orgusersList =
    _orgusersList?.results.map(p => ({
      is_archived: p.is_archived,
      value: p.pk,
      label: p.display,
      handleClick: () => {
        isPagination
          ? SetFilterQuery({ orguser: p.pk })
          : setQueries(prev => ({ ...prev, orguser: p.pk }))
      }
    })) || []

  const contractorsList =
    (_contractorsList &&
      _contractorsList.results.map(c => ({
        value: c.id,
        label: c.display,
        handleClick: () => {
          isPagination
            ? SetFilterQuery({ contractor: c.id })
            : setQueries(prev => ({ ...prev, contractor: c.id }))
        }
      }))) ||
    []
  const rolesList =
    (_rolesList &&
      _rolesList.results.map(r => ({
        value: r.pk,
        label: r.title,
        handleClick: () => {
          isPagination
            ? SetFilterQuery({ role: r.pk })
            : setQueries(prev => ({ ...prev, role: r.pk }))
        }
      }))) ||
    []
  const tagsList =
    (_tagsList &&
      _tagsList.results.map(t => ({
        value: t.pk,
        label: t.name,
        handleClick: () => {
          const tagField = project_tags ? 'project_tags' : 'tags'
          let newArray = queries[tagField] || []
          if (queries[tagField] && queries[tagField].includes(t.pk)) {
            newArray = newArray.filter(f => f !== t.pk)
          } else {
            newArray = [...newArray, t.pk]
          }
          isPagination
            ? SetFilterQuery({ [tagField]: newArray })
            : setQueries(prev => ({
                ...prev,
                [tagField]: newArray
              }))
        }
      }))) ||
    []
  const userTags =
    (_userTags &&
      _userTags.results.map(t => ({
        value: t.id,
        label: t.name,
        handleClick: () => {
          let newArray = queries.tags || []
          if (queries.tags && queries.tags.includes(t.id))
            newArray = newArray.filter(f => f !== t.id)
          else newArray = [...newArray, t.id]
          SetFilterQuery({ tags: newArray })
        }
      }))) ||
    []

  const phasesList =
    (_phasesList &&
      _phasesList.results.map(p => ({
        value: p.id,
        label: p.display,
        handleClick: () => {
          isPagination
            ? SetFilterQuery({ phase: p.id })
            : setQueries(prev => ({ ...prev, phase: p.id }))
        }
      }))) ||
    []

  const annexesList =
    (_annexesList &&
      _annexesList.results.map(p => ({
        value: p.id,
        label: p.display,
        handleClick: () => {
          isPagination
            ? SetFilterQuery({ annex: p.id })
            : setQueries(prev => ({ ...prev, annex: p.id }))
        }
      }))) ||
    []
  const citiesList = []
  if (_citiesList) {
    const citiesByCountry = Object.values(_citiesList)
    citiesByCountry.forEach(cities => {
      cities.forEach(city => {
        if (cities_projects) {
          if (cities_projects.includes(city)) {
            citiesList.push({
              label: city,
              value: city,
              handleClick: () => {
                return isPagination
                  ? SetFilterQuery({ city })
                  : setQueries(prev => ({ ...prev, city }))
              }
            })
          }
        } else if (cities_opportunities) {
          if (cities_opportunities.includes(city)) {
            citiesList.push({
              label: city,
              value: city,
              handleClick: () => {
                return isPagination
                  ? SetFilterQuery({ city })
                  : setQueries(prev => ({ ...prev, city }))
              }
            })
          }
        } else {
          citiesList.push({
            label: city,
            value: city,
            handleClick: () => {
              return isPagination
                ? SetFilterQuery({ city })
                : setQueries(prev => ({ ...prev, city }))
            }
          })
        }
      })
    })
  }

  // const citiesList =
  //   (_citiesList && (
  //     Object.values(_citiesList)
  //     // .map(c => ({
  //     //   value: c,
  //     //   label: c,
  //     //   handleClick: () => {
  //     //     return (
  //     //     isPagination
  //     //       ? SetFilterQuery({ city: c })
  //     //       : setQueries(prev => ({ ...prev, city: c })))
  //     //   }
  //     // }))
  //   )) ||
  //   []
  const currenciesList =
    (_currenciesList &&
      _currenciesList.results.map(p => ({
        value: p.id,
        label: p.display,
        handleClick: () => {
          isPagination
            ? SetFilterQuery({ project: p.id })
            : setQueries(prev => ({ ...prev, project: p.id }))
        }
      }))) ||
    []

  const taskList =
    (_taskLists &&
      _taskLists.results.map(p => ({
        value: p.id,
        label: p.display,
        handleClick: () => {
          isPagination
            ? SetFilterQuery({ list_task: p.id })
            : setQueries(prev => ({ ...prev, list_task: p.id }))
        }
      }))) ||
    []
  // const typeList = Object.values(TYPES).map(type => ({
  //   label: type,
  //   value: type,
  //   handleClick: () => setQueries(prev => ({ ...prev, type: type }))
  // }))
  const typeList = TYPE_LIST.map(p => ({
    label: p.label,
    value: p.value,
    handleClick: () => {
      return isPagination
        ? SetFilterQuery({ type: p.value })
        : setQueries(prev => ({ ...prev, type: p.value }))
    }
  }))

  // const clientList =
  //   (_clientList &&
  //     _clientList.results.map(p => ({
  //       value: p.pk,
  //       label: p.display,
  //       handleClick: () => {
  //         isPagination
  //           ? SetFilterQuery({ clents: p.pk })
  //           : setQueries(prev => ({ ...prev, clents: p.pk }))
  //       }
  //     }))) ||
  //   []
  const typesList =
    (_typesList &&
      _typesList.results.map(t => ({
        value: t.id,
        label: t.display,
        handleClick: () => setQueries(prev => ({ ...prev, type: t.id }))
      }))) ||
    []

  const stagesList =
    (_stagesList &&
      _stagesList.results.map(s => ({
        value: s.pk,
        label: s.title,
        handleClick: () => setQueries(prev => ({ ...prev, stage: s.pk }))
      }))) ||
    []

  /* Static filters */
  const isPaid = [
    {
      value: 1,
      label: dictionary.yes,
      handleClick: () => {
        isPagination
          ? SetFilterQuery({ is_paid: true })
          : setQueries(prev => ({ ...prev, is_paid: true }))
      }
    },
    {
      value: 2,
      label: dictionary.no,
      handleClick: () => {
        isPagination
          ? SetFilterQuery({ is_paid: false })
          : setQueries(prev => ({ ...prev, is_paid: false }))
      }
    }
  ]

  const isSyncBudget = [
    {
      value: 3,
      label: dictionary.sync_with_budget,
      handleClick: () => {
        isPagination
          ? SetFilterQuery({ sync_with_budget: true })
          : setQueries(prev => ({ ...prev, sync_with_budget: true }))
      }
    },
    {
      value: 4,
      label: dictionary.unsync_with_budget,
      handleClick: () => {
        isPagination
          ? SetFilterQuery({ sync_with_budget: false })
          : setQueries(prev => ({ ...prev, sync_with_budget: false }))
      }
    }
  ]

  const isPlanning = [
    {
      value: 5,
      label: dictionary.sync_with_planning,
      handleClick: () => {
        isPagination
          ? SetFilterQuery({ sync_with_planning: true })
          : setQueries(prev => ({ ...prev, sync_with_planning: true }))
      }
    },
    {
      value: 6,
      label: dictionary.unsync_with_planning,
      handleClick: () => {
        isPagination
          ? SetFilterQuery({ sync_with_planning: false })
          : setQueries(prev => ({ ...prev, sync_with_planning: false }))
      }
    }
  ]

  const isOpen = [
    {
      value: 7,
      label: dictionary.active,
      handleClick: () => {
        isPagination
          ? SetFilterQuery({ is_open: true })
          : setQueries(prev => ({ ...prev, is_open: true }))
      }
    },
    {
      value: 8,
      label: dictionary.close,
      handleClick: () => {
        isPagination
          ? SetFilterQuery({ is_open: false })
          : setQueries(prev => ({ ...prev, is_open: false }))
      }
    }
  ]

  const isInternal = [
    {
      value: '',
      label: dictionary.all_types,
      handleClick: () => {
        const newQuery = { ...queries }
        delete newQuery.is_internal
        setQueries({ ...newQuery }, true)
      }
    },
    {
      value: 1,
      label: dictionary.external,
      handleClick: () => SetFilterQuery({ is_internal: false })
    },
    {
      value: 2,
      label: dictionary.internal,
      handleClick: () => SetFilterQuery({ is_internal: true })
    }
  ]

  const userState = [
    {
      value: '',
      label: dictionary.all,
      handleClick: () => {
        const newQuery = { ...queries }
        delete newQuery.archived
        SetFilterQuery({ ...newQuery, all_users: true }, true)
      }
    },
    {
      value: 1,
      label: dictionary.active,
      handleClick: () => {
        const newQuery = { ...queries }
        delete newQuery.archived
        delete newQuery.all_users
        SetFilterQuery({ ...newQuery }, true)
      }
    },
    {
      value: 2,
      label: dictionary.archived,
      handleClick: () => {
        const newQuery = { ...queries }
        delete newQuery.all_users
        SetFilterQuery({ ...newQuery, archived: true }, true)
      }
    }
  ]

  const userStatus = [
    {
      value: '',
      label: dictionary.all_statuses,
      handleClick: () => SetFilterQuery({ status: '' })
    },
    {
      value: 'not_invited',
      label: dictionary.not_invited,
      handleClick: () => SetFilterQuery({ status: 'not_invited' })
    },
    {
      value: 'invite_pending',
      label: dictionary.invite_pending,
      handleClick: () => SetFilterQuery({ status: 'invite_pending' })
    },
    {
      value: 'active',
      label: dictionary.present,
      handleClick: () => SetFilterQuery({ status: 'active' })
    },
    {
      value: 'away',
      label: dictionary.away,
      handleClick: () => SetFilterQuery({ status: 'away' })
    }
  ]

  const tasksStatus = [
    {
      value: undefined,
      label: dictionary.all_statuses,
      handleClick: () => {
        const newQuery = { ...queries }
        delete newQuery.is_logged
        SetFilterQuery(newQuery, true)
      }
    },
    {
      value: false,
      label: dictionary.active,
      handleClick: () => SetFilterQuery({ is_logged: false })
    },
    {
      value: true,
      label: dictionary.logged,
      handleClick: () => SetFilterQuery({ is_logged: true })
    }
  ]

  const tasksState = [
    {
      value: undefined,
      label: dictionary.all_states,
      handleClick: () => {
        const newQuery = { ...queries }
        delete newQuery.is_completed
        SetFilterQuery(newQuery, true)
      }
    },
    {
      value: true,
      label: dictionary.completed,
      handleClick: () => SetFilterQuery({ is_completed: true })
    },
    {
      value: false,
      label: dictionary.not_completed,
      handleClick: () => SetFilterQuery({ is_completed: false })
    }
  ]

  let expenseStatusList = []
  if (expenseStatus) {
    expenseStatusList = [
      { label: dictionary.all_statuses, value: '' },
      { label: dictionary.not_reviewed, value: 'not_reviewed' },
      { label: dictionary.not_reimbursed_yet, value: 'not_reimbursed_yet' },
      { label: dictionary.reimbursed, value: 'reimbursed' },
      router.query.view === 'team'
        ? { label: dictionary.billable, value: 'billable' }
        : null,
      router.query.view === 'team'
        ? { label: dictionary.billed, value: 'billed' }
        : null
    ]
      .filter(o => o)
      .map(e => ({
        label: e.label,
        value: e.value,
        handleClick: () =>
          isPagination
            ? SetFilterQuery({ status: e.value })
            : setQueries(prev => ({ ...prev, status: e.value }))
      }))
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'filter-popover' : undefined

  const billableList = [
    {
      label: `${dictionary.billable}?`,
      value: 'all',
      handleClick: () => {
        const newQuery = { ...queries }
        delete newQuery.is_billable
        SetFilterQuery(newQuery, true)
      }
    },
    {
      label: dictionary.billable,
      value: true,
      handleClick: () => SetFilterQuery({ is_billable: true })
    },
    {
      label: dictionary.not_billable,
      value: false,
      handleClick: () => SetFilterQuery({ is_billable: false })
    }
  ]

  const reimbursableList = [
    {
      label: `${dictionary.reimbursable}?`,
      value: 'all',
      handleClick: () => {
        const newQuery = { ...queries }
        delete newQuery.is_payable
        SetFilterQuery(newQuery, true)
      }
    },
    {
      label: dictionary.reimbursable,
      value: true,
      handleClick: () => SetFilterQuery({ is_payable: true })
    },
    {
      label: dictionary.not_reimbursable,
      value: false,
      handleClick: () => SetFilterQuery({ is_payable: false })
    }
  ]

  const expenseCategoriesList =
    categories &&
    categories.results?.map(category => ({
      value: category.id,
      label: category.display,
      handleClick: () => {
        SetFilterQuery({ category: category.id })
      }
    }))

  const expenseSuppliersList =
    expenseSuppliers &&
    expenseSuppliers.results?.map(supplier => ({
      value: supplier.id,
      label: supplier.display,
      handleClick: () => {
        SetFilterQuery({ supplier: supplier.id })
      }
    }))

  // const expenseCategoriesList = [
  //   {
  //     value: 'all',
  //     label: dictionary.any_category,
  //     handleClick: () => {
  //       const newQuery = { ...queries }
  //       delete newQuery.category
  //       SetFilterQuery(newQuery, true)
  //     }
  //   },
  //   {
  //     value: 'car rental',
  //     label: dictionary.car_rental,
  //     handleClick: () => SetFilterQuery({ category: 'car rental' })
  //   },
  //   {
  //     value: 'parking',
  //     label: dictionary.parking,
  //     handleClick: () => SetFilterQuery({ category: 'parking' })
  //   },
  //   {
  //     value: 'travel',
  //     label: dictionary.airplane_train_bus,
  //     handleClick: () => SetFilterQuery({ category: 'travel' })
  //   },
  //   {
  //     value: 'taxi',
  //     label: dictionary.taxi,
  //     handleClick: () => SetFilterQuery({ category: 'taxi' })
  //   },
  //   {
  //     value: 'toll',
  //     label: dictionary.toll,
  //     handleClick: () => SetFilterQuery({ category: 'toll' })
  //   },
  //   {
  //     value: 'housing',
  //     label: dictionary.housing,
  //     handleClick: () => SetFilterQuery({ category: 'housing' })
  //   },
  //   {
  //     value: 'phone',
  //     label: dictionary.telephone,
  //     handleClick: () => SetFilterQuery({ category: 'phone' })
  //   },
  //   {
  //     value: 'restaurants',
  //     label: dictionary.restaurants,
  //     handleClick: () => SetFilterQuery({ category: 'restaurants' })
  //   },
  //   {
  //     value: 'misc',
  //     label: dictionary.divers,
  //     handleClick: () => SetFilterQuery({ category: 'misc' })
  //   },
  //   {
  //     value: 'mileage',
  //     label: dictionary.mileage,
  //     handleClick: () => SetFilterQuery({ category: 'mileage' })
  //   }
  // ]
  const onSubmitCustomDates = () => {
    setQueries(prev => {
      const startDate = dateRequestedOn ? 'start_requested_on' : 'start_date'
      const endDate = dateRequestedOn ? 'end_requested_on' : 'end_date'

      if (finance) {
        return {
          ...prev,
          period_start: format(watch('start_date'), 'dd-MM-yyyy'),
          period_end: format(watch('end_date'), 'dd-MM-yyyy')
        }
      }

      return invoice
        ? {
            ...prev,
            page: 1,
            invoice_date_start: format(watch('start_date'), 'dd-MM-yyyy'),
            invoice_date_end: format(watch('end_date'), 'dd-MM-yyyy')
          }
        : {
            ...prev,
            page: 1,
            [startDate]: format(watch('start_date'), 'dd-MM-yyyy'),
            [endDate]: format(watch('end_date'), 'dd-MM-yyyy')
          }
    })
  }

  return (
    <>
      <IconButton
        disabled={disabled}
        onClick={handleClick}
        sx={{
          borderRadius: '12px',
          border: `1px solid #E0E0E0`,
          paddingX: 2,
          height: height,
          backgroundColor: 'white'
        }}
        data-testid="IconButton-7b21b00b-655b-4360-bab7-6207e2002184"
      >
        <Badge
          badgeContent={filterActivated}
          sx={{ display: 'flex', alignItems: 'center', gap: 1.5, margin: 0 }}
        >
          <FilterList sx={{ color: 'text.light', fontSize: 15 }} />
          <Typography
            variant="button"
            color="text.main"
            textTransform="none"
            data-testid="Typography-8dab8e90-51ec-447f-98a9-f14feb55d25d"
          >
            {dictionary.filters}
          </Typography>
        </Badge>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className={classes.root}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left'
        }}
        PaperProps={
          openDatePicker
            ? {
                style: {
                  overflowX: 'visible',
                  width: '800px',
                  maxWidth: 'none'
                }
              }
            : {}
        }
      >
        <Grid
          container
          item
          xs={12}
          sx={{ borderRadius: t => t.spacing(1), width: '300%' }}
        >
          <Grid
            item
            xs={12}
            display="flex"
            alignItems="center"
            justifyContent={'space-between'}
            padding={2}
          >
            {clearButton && (
              <Tooltip title={dictionary.clear}>
                <IconButton
                  onClick={() => {
                    isPagination
                      ? SetFilterQuery(initialQueries, true)
                      : setQueries(initialQueries)
                  }}
                  color="warning"
                  data-testid="IconButton-3442f1e3-0023-4c99-8afd-cd179ae76d6a"
                >
                  <RestartAltIcon color="warning" />
                </IconButton>
              </Tooltip>
            )}

            <Typography
              variant="overline"
              color="black"
              data-testid="Typography-9690ca04-3ef2-4873-903f-60d9e64bf5f9"
            >
              {dictionary.filters}
            </Typography>
            <IconButton
              onClick={handleClose}
              color="error"
              data-testid="IconButton-148beaf4-e38d-487c-ade0-79a6ddd6660f"
            >
              <CloseIcon color="error" />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} container display="flex">
            <Grid item container spacing={2} p={2} xs={openDatePicker ? 6 : 12}>
              {project && (
                <Grid item xs={12}>
                  <Select
                    name={dictionary.all_projects}
                    fullWidth
                    label={dictionary.all_projects}
                    value={queries.operation || ''}
                    data_testid="Select-c4dcd463-1b5a-444f-b8fe-82cdc2cc65ce"
                  >
                    <MenuItem
                      value=""
                      onClick={() => {
                        const newQuery = { ...queries }
                        delete newQuery.operation
                        isPagination
                          ? SetFilterQuery(newQuery, true)
                          : setQueries(prev => {
                              delete prev.operation
                              return { ...prev }
                            })
                      }}
                      data-testid="MenuItem-1e3b2a1d-5e3d-4347-b7e4-308e59e2cd77"
                    >
                      {dictionary.all_projects}
                    </MenuItem>
                    {projectsList?.map(item => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        onClick={() => {
                          isPagination
                            ? SetFilterQuery({ operation: item.value })
                            : setQueries(prev => {
                                return { ...prev, operation: item.value }
                              })
                        }}
                        data-testid="MenuItem-36c25ade-d324-4f37-8643-b5c6fe8ddec0"
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}

              {orguser && (
                <Grid item xs={12}>
                  <Select
                    name={dictionary.all_users}
                    fullWidth
                    label={dictionary.all_users}
                    value={queries.orguser || ''}
                    data_testid="Select-b76ff361-76d0-4801-a579-c7ca1bf3b224"
                  >
                    <MenuItem
                      value=""
                      onClick={() => {
                        const newQuery = { ...queries }
                        delete newQuery.orguser
                        isPagination
                          ? SetFilterQuery(newQuery, true)
                          : setQueries(prev => {
                              delete prev.orguser
                              return { ...prev }
                            })
                      }}
                      data-testid="MenuItem-6d5b52b8-465b-4180-bc1a-5f1a17ac6c8a"
                    >
                      {dictionary.all_users}
                    </MenuItem>
                    <ListSubheader>{dictionary.active}</ListSubheader>
                    {orgusersList
                      ?.filter(u => !u.is_archived)
                      .map(item => (
                        <MenuItem
                          key={item.value}
                          value={item.value}
                          onClick={() => {
                            isPagination
                              ? SetFilterQuery({ orguser: item.value })
                              : setQueries(prev => {
                                  return { ...prev, orguser: item.value }
                                })
                          }}
                          data-testid="MenuItem-5497a115-0f0f-46c8-8fb4-bfd3f2c14153"
                        >
                          {item.label}
                        </MenuItem>
                      ))}

                    <ListSubheader>{dictionary.archived}</ListSubheader>
                    {orgusersList
                      ?.filter(u => u.is_archived)
                      .map(item => (
                        <MenuItem
                          key={item.value}
                          value={item.value}
                          onClick={() => {
                            isPagination
                              ? SetFilterQuery({ orguser: item.value })
                              : setQueries(prev => {
                                  return { ...prev, orguser: item.value }
                                })
                          }}
                          data-testid="MenuItem-632352ad-545b-4053-9e83-a791e1f72d95"
                        >
                          {item.label}
                        </MenuItem>
                      ))}
                  </Select>
                </Grid>
              )}

              {contractor && (
                <Grid item xs={12}>
                  <Select
                    name={dictionary.contractors}
                    fullWidth
                    label={dictionary.contractors}
                    value={queries.contractor || ''}
                    data_testid="Select-44d87426-f525-4e49-8667-563b5a495e6d"
                  >
                    <MenuItem
                      value=""
                      onClick={() => {
                        const newQuery = { ...queries }
                        delete newQuery.contractor
                        isPagination
                          ? SetFilterQuery(newQuery, true)
                          : setQueries(prev => {
                              delete prev.contractor
                              return { ...prev }
                            })
                      }}
                      data-testid="MenuItem-65b3836f-fd85-47f9-9f4c-b349b312324c"
                    >
                      {dictionary.all_contractors}
                    </MenuItem>
                    {contractorsList?.map(item => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        onClick={() => item.handleClick()}
                        data-testid="MenuItem-c9759940-10fa-4d23-84d6-bbb6d72859de"
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}

              {role && (
                <Grid item xs={12}>
                  <Select
                    name={dictionary.any_role}
                    fullWidth
                    label={dictionary.any_role}
                    value={queries.role || ''}
                    data_testid="Select-9a6af303-b9c8-4b6f-87c2-bfd079d3ae31"
                  >
                    <MenuItem
                      value=""
                      onClick={() => {
                        const newQuery = { ...queries }
                        delete newQuery.role
                        isPagination
                          ? SetFilterQuery(newQuery, true)
                          : setQueries(prev => {
                              delete prev.role
                              return { ...prev }
                            })
                      }}
                      data-testid="MenuItem-e41b3578-5f35-444e-980d-bd720d3f5e65"
                    >
                      {dictionary.any_role}
                    </MenuItem>
                    {rolesList?.map(item => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        onClick={() => {
                          isPagination
                            ? SetFilterQuery({ role: item.value })
                            : setQueries(prev => {
                                return { ...prev, role: item.value }
                              })
                        }}
                        data-testid="MenuItem-e6aecb78-f98e-44eb-ac4e-eb2428e8420a"
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}

              {(tag || project_tags) && (
                <Grid item xs={12}>
                  <Select
                    name={dictionary.any_tag}
                    fullWidth
                    label={dictionary.any_tag}
                    value={
                      (project_tags ? queries.project_tags : queries.tags) || []
                    }
                    multiple
                    data_testid="Select-40a312de-14f5-4bff-b7eb-8bbabcc97cdf"
                  >
                    <MenuItem
                      value={[]}
                      onClick={() => {
                        isPagination
                          ? SetFilterQuery({
                              [tag ? 'tags' : 'project_tags']: []
                            })
                          : setQueries(prev => {
                              const query = { ...prev }
                              if (tag) delete query.tags
                              else delete query.project_tags

                              return query
                            })
                      }}
                      data-testid="MenuItem-7bcdad49-74ed-4d54-8d44-cc9de07ff0a4"
                    >
                      {dictionary.all_tags}
                    </MenuItem>
                    {tagsList?.map(item => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        onClick={() => item.handleClick()}
                        data-testid="MenuItem-01f3163d-ff4a-4c31-be5f-983bdcc53a9e"
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}

              {tags && (
                <Grid item xs={12}>
                  <Select
                    fullWidth
                    name={dictionary.tags}
                    label={dictionary.tags}
                    value={queries.tags || []}
                    multiple
                    data_testid="Select-8dfc8b52-e628-4d0f-9ec1-5f31da8f98de"
                  >
                    <MenuItem
                      value={[]}
                      onClick={() => SetFilterQuery({ tags: [] })}
                      data-testid="MenuItem-afe5153d-5abb-4eeb-b8c7-245f3bb23c3c"
                    >
                      {dictionary.all_tags}
                    </MenuItem>
                    {userTags?.map(item => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        onClick={() => item.handleClick()}
                        data-testid="MenuItem-4afb426c-0b8d-4eff-a750-878bd6a89f02"
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}

              {project_status && (
                <Grid item xs={12}>
                  <Select
                    fullWidth
                    name={dictionary.status}
                    label={dictionary.status}
                    value={queries.status || ''}
                    data_testid="Select-1f358ff0-461c-48b8-bb7b-740947ec8145"
                  >
                    {projectStatus(dictionary, setQueries)?.map(item => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        onClick={() => item.handleClick()}
                        data-testid="MenuItem-1842293a-18ee-4430-972e-046fcafe84fb"
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}

              {phase && phasesList?.length > 0 && (
                <Grid item xs={12}>
                  <Select
                    name={dictionary.all_phases}
                    fullWidth
                    label={dictionary.all_phases}
                    value={queries.phase || ''}
                    data_testid="Select-79893ec4-0398-4456-898b-4c7cc83bae46"
                  >
                    <MenuItem
                      value=""
                      onClick={() => {
                        const newQuery = { ...queries }
                        delete newQuery.phase
                        isPagination
                          ? SetFilterQuery(newQuery, true)
                          : setQueries(prev => {
                              delete prev.phase
                              return { ...prev }
                            })
                      }}
                      data-testid="MenuItem-990b299f-f4c6-4d77-ac3e-c4d74df49f99"
                    >
                      {dictionary.all_phases}
                    </MenuItem>
                    {phasesList?.map(item => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        onClick={() => item.handleClick()}
                        data-testid="MenuItem-be44ff50-11fd-4625-9e82-db53a0353395"
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}

              {annex && annexesList?.length > 0 && (
                <Grid item xs={12}>
                  <Select
                    name={dictionary.annexes}
                    fullWidth
                    label={dictionary.annexes}
                    value={queries.annex || ''}
                    data_testid="Select-4b5e2c18-bd15-4ca9-b3e2-32fafc44221c"
                  >
                    <MenuItem
                      value=""
                      onClick={() => {
                        const newQuery = { ...queries }
                        delete newQuery.annex
                        isPagination
                          ? SetFilterQuery(newQuery, true)
                          : setQueries(prev => {
                              delete prev.annex
                              return { ...prev }
                            })
                      }}
                      data-testid="MenuItem-f0f2ec93-9a66-48dd-bb94-c99f94b9f9f3"
                    >
                      {dictionary.all_annexes}
                    </MenuItem>
                    {annexesList?.map(item => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        onClick={() => item.handleClick()}
                        data-testid="MenuItem-ad4f5172-6933-4b33-ab51-a9c12f8698f7"
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}

              {cities && (
                <Grid item xs={12}>
                  <Select
                    name={dictionary.all_cities}
                    fullWidth
                    label={dictionary.all_cities}
                    value={queries.city || ''}
                    data_testid="Select-3c4bf238-0ad9-45a7-b929-1c3a6c03f81b"
                  >
                    <MenuItem
                      value=""
                      onClick={() => {
                        const newQuery = { ...queries }
                        delete newQuery.city
                        isPagination
                          ? SetFilterQuery(newQuery, true)
                          : setQueries(prev => {
                              delete prev.city
                              return { ...prev }
                            })
                      }}
                      data-testid="MenuItem-aff58025-530d-45ea-b4e5-bcccc4e13e19"
                    >
                      {dictionary.all_cities}
                    </MenuItem>
                    {citiesList?.map(item => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        onClick={() => {
                          isPagination
                            ? SetFilterQuery({ city: item.value })
                            : setQueries(prev => {
                                return { ...prev, city: item.value }
                              })
                        }}
                        data-testid="MenuItem-f9c54bf6-85b0-4d00-b278-bf39df6fc537"
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}

              {(currencies || client_currency) && (
                <Grid item xs={12}>
                  <Select
                    name={dictionary.currencies}
                    fullWidth
                    label={dictionary.currencies}
                    value={
                      (client_currency
                        ? queries.client_currency
                        : queries.currency) || ''
                    }
                    data_testid="Select-cd134b03-4c7e-4ef2-a869-cefc2b5c3cab"
                  >
                    <MenuItem
                      value=""
                      onClick={() => {
                        const newQuery = { ...queries }

                        if (client_currency) delete newQuery.client_currency
                        else delete newQuery.currency
                        isPagination
                          ? SetFilterQuery(newQuery, true)
                          : setQueries(prev => {
                              if (client_currency) delete prev.client_currency
                              else delete prev.currency
                              return { ...prev }
                            })
                      }}
                      data-testid="MenuItem-ce07bbc9-f366-441f-b97d-41485ac83a96"
                    >
                      {dictionary.currencies}
                    </MenuItem>
                    {currenciesList?.map(item => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        onClick={() => {
                          isPagination
                            ? SetFilterQuery({
                                [client_currency
                                  ? 'client_currency'
                                  : 'currency']: item.value
                              })
                            : setQueries(prev => {
                                return {
                                  ...prev,
                                  [client_currency
                                    ? 'client_currency'
                                    : 'currency']: item.value
                                }
                              })
                        }}
                        data-testid="MenuItem-41a02c7b-8122-48b8-8cde-9b660c9c911c"
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}

              {task_list && (
                <Grid item xs={12}>
                  <Select
                    name={dictionary.all_lists}
                    fullWidth
                    label={dictionary.all_lists}
                    value={queries.list_task || ''}
                    data_testid="Select-3c4bf238-0ad9-45a7-b929-1c3a6c03f81b"
                  >
                    <MenuItem
                      value=""
                      onClick={() => {
                        const newQuery = { ...queries }
                        delete newQuery.list_task
                        isPagination
                          ? SetFilterQuery(newQuery, true)
                          : setQueries(prev => {
                              delete prev.list_task
                              return { ...prev }
                            })
                      }}
                      data-testid="MenuItem-aff58025-530d-45ea-b4e5-bcccc4e13e19"
                    >
                      {dictionary.all_lists}
                    </MenuItem>
                    {taskList?.map(item => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        onClick={() => {
                          isPagination
                            ? SetFilterQuery({ list_task: item.value })
                            : setQueries(prev => {
                                return { ...prev, list_task: item.value }
                              })
                        }}
                        data-testid="MenuItem-f9c54bf6-85b0-4d00-b278-bf39df6fc537"
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}

              {type && (
                <Grid item xs={12}>
                  <Select
                    name={dictionary.all_types}
                    fullWidth
                    label={dictionary.all_types}
                    value={queries.type || ''}
                    data_testid="Select-ac2f6c54-ae56-47eb-a866-ce4c64d38730"
                  >
                    <MenuItem
                      value=""
                      onClick={() => {
                        const newQuery = { ...queries }
                        delete newQuery.type
                        isPagination
                          ? SetFilterQuery(newQuery, true)
                          : setQueries(prev => {
                              delete prev.type
                              return { ...prev }
                            })
                      }}
                      data-testid="MenuItem-a7657d78-44e0-4c18-8d6f-984840cd3ce2"
                    >
                      {dictionary.all_types}
                    </MenuItem>
                    {typeList?.map(item => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        onClick={() => {
                          isPagination
                            ? SetFilterQuery({ type: item.value })
                            : setQueries(prev => {
                                return { ...prev, type: item.value }
                              })
                        }}
                        data-testid="MenuItem-1d27580a-4908-4de7-b489-386da4db775f"
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}

              {/* {clients && (
                      <Grid item xs={12}>
                        <Select
                          name={dictionary.clients}
                          fullWidth
                          label={dictionary.clients}
                          value={queries.client || ''}
                        >
                          <MenuItem
                            value=""
                            onClick={() => {
                              const newQuery = { ...queries }
                              delete newQuery.client
                               isPagination
                                ? SetFilterQuery(newQuery, true)
                                : setQueries(prev => {
                                    delete prev.client
                                     return { ...prev }
                                  })
                            }}
                          >
                            {dictionary.clients}
                          </MenuItem>
                          {clientList?.map(item => (
                            <MenuItem
                              key={item.value}
                              value={item.value}
                              onClick={() => {
                                isPagination
                                  ? SetFilterQuery({
                                      client: item.value
                                    })
                                  : setQueries(prev => {
                                      return {
                                        ...prev,
                                        client: item.value
                                      }
                                    })
                              }}
                            >
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                     )} */}

              {is_paid && (
                <Grid item xs={12}>
                  <Select
                    fullWidth
                    name={dictionary.is_paid}
                    label={dictionary.is_paid}
                    value={
                      queries.is_paid ? 1 : queries.is_paid === false ? 2 : ''
                    }
                    data_testid="Select-b6a557de-b9fd-4cac-86cc-02435900f918"
                  >
                    <MenuItem
                      value=""
                      onClick={() => {
                        const newQuery = { ...queries }
                        delete newQuery.is_paid
                        isPagination
                          ? SetFilterQuery(newQuery, true)
                          : setQueries(prev => {
                              delete prev.is_paid
                              return { ...prev }
                            })
                      }}
                      data-testid="MenuItem-c9e1f687-903f-46e1-8c7b-f85cc6826565"
                    >
                      {dictionary.is_paid}
                    </MenuItem>
                    {isPaid?.map(item => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        onClick={() => item.handleClick()}
                        data-testid="MenuItem-f87dfbd9-b933-4455-8531-0679d03f29ab"
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}

              {payment_type && (
                <Grid item xs={12}>
                  <Select
                    fullWidth
                    name={dictionary.Invoicing.payment_type}
                    label={dictionary.Invoicing.payment_type}
                    value={
                      (payment_type_different
                        ? queries.payment_type
                        : queries.type) || ''
                    }
                    data_testid="Select-7f162dfc-7077-4eb6-b4b7-f8e0ba0b50a2"
                  >
                    {paymentType(
                      dictionary,
                      setQueries,
                      payment_type_different
                    )?.map(item => {
                      return (
                        <MenuItem
                          key={item.value}
                          value={item.value}
                          onClick={() => item.handleClick()}
                          data-testid="MenuItem-4342e3be-7d39-4d9e-8a5e-3f4a430b5542"
                        >
                          {item.label}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </Grid>
              )}

              {timelog_type && (
                <Grid item xs={12}>
                  <Select
                    fullWidth
                    name={dictionary.type}
                    label={dictionary.type}
                    value={queries.type || ''}
                    data_testid="Select-11a7b334-b9e6-450d-a382-73ee54ec4fd8"
                  >
                    <MenuItem
                      value=""
                      onClick={() =>
                        setQueries(prev => {
                          delete prev.type
                          return { ...prev }
                        })
                      }
                      data-testid="MenuItem-da0cc7ff-0a41-4453-bb8a-4e90cccb8c99"
                    >
                      {dictionary.all_types}
                    </MenuItem>
                    {typesList?.map(item => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        onClick={() => item.handleClick()}
                        data-testid="MenuItem-0d7b09df-08a9-4de3-b559-d275eb58594f"
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}

              {sync_with_budget && (
                <Grid item xs={12}>
                  <Select
                    name={dictionary.budget}
                    fullWidth
                    label={dictionary.budget}
                    value={
                      queries.sync_with_budget
                        ? 3
                        : queries.sync_with_budget === false
                        ? 4
                        : ''
                    }
                    data_testid="Select-70bfeb69-7ebb-44d9-b2df-9b8038a424db"
                  >
                    <MenuItem
                      value=""
                      onClick={() => {
                        const newQuery = { ...queries }
                        delete newQuery.sync_with_budget
                        isPagination
                          ? SetFilterQuery(newQuery, true)
                          : setQueries(prev => {
                              delete prev.sync_with_budget
                              return { ...prev }
                            })
                      }}
                      data-testid="MenuItem-714589a5-8c03-4aab-97e5-4dae914b3a0b"
                    >
                      {dictionary.budget}
                    </MenuItem>
                    {isSyncBudget?.map(item => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        onClick={() => item.handleClick()}
                        data-testid="MenuItem-90594f74-4800-407d-abf4-e1726a1c9256"
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}

              {valid_status && (
                <Grid item xs={12}>
                  <Select
                    fullWidth
                    name={dictionary.status}
                    label={dictionary.status}
                    value={queries.is_validated || ''}
                    data_testid="Select-b1e7b217-32b4-453c-b171-4dfce1f9bbed"
                  >
                    {validStatus(dictionary, setQueries)?.map(item => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        onClick={() => item.handleClick()}
                        data-testid="MenuItem-7f16ed5b-0372-464f-bb9a-c42e6eea46fc"
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}

              {job_status && (
                <Grid item xs={12}>
                  <Select
                    fullWidth
                    name={dictionary.status}
                    label={dictionary.status}
                    value={queries.is_validated || ''}
                    data_testid="Select-01321403-5bd3-499b-bebd-65fcf7e6475e"
                  >
                    {validStatus(dictionary, setQueries)?.map(item => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        onClick={() => item.handleClick()}
                        data-testid="MenuItem-ea9b5412-ed7c-4fc9-9b09-a567ae63a4a4"
                      >
                        {item.label}
                      </MenuItem>
                    ))}

                    <MenuItem
                      value={'not_reviewed'}
                      onClick={() =>
                        setQueries &&
                        setQueries(prev => ({
                          ...prev,
                          is_validated: 'not_reviewed'
                        }))
                      }
                      data-testid="MenuItem-975ff03e-d950-4269-81e5-da393cbbf25b"
                    >
                      {dictionary.not_reviewed}
                    </MenuItem>
                  </Select>
                </Grid>
              )}

              {is_recuperation && (
                <Grid item xs={12}>
                  <Select
                    fullWidth
                    name={`${dictionary.recuperation}?`}
                    label={`${dictionary.recuperation}?`}
                    value={
                      queries.is_recuperation
                        ? 1
                        : queries.is_recuperation === false
                        ? 2
                        : ''
                    }
                    data_testid="Select-b57599fe-e11f-43b1-aa8b-a341d8cfb2a0"
                  >
                    {isRecuperation(
                      dictionary,
                      isPagination ? SetFilterQuery : setQueries,
                      isPagination,
                      queries
                    )?.map(item => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        onClick={() => item.handleClick()}
                        data-testid="MenuItem-2516f2cf-898b-4bb6-9630-910df7677449"
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}

              {is_imported && (
                <Grid item xs={12}>
                  <Select
                    fullWidth
                    name={`${dictionary.imported}?`}
                    label={`${dictionary.imported}?`}
                    value={
                      queries.is_imported
                        ? 1
                        : queries.is_imported === false
                        ? 2
                        : ''
                    }
                    data_testid="Select-1f1550f1-c380-4a67-9f16-8f317cb7afb9"
                  >
                    {isImported(dictionary, setQueries)?.map(item => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        onClick={() => item.handleClick()}
                        data-testid="MenuItem-5562e496-c2d6-4985-9be1-8a1c008f924c"
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}

              {sync_with_planning && (
                <Grid item xs={12}>
                  <Select
                    name={dictionary.planning}
                    fullWidth
                    label={dictionary.planning}
                    value={
                      queries.sync_with_planning
                        ? 5
                        : queries.sync_with_planning === false
                        ? 6
                        : ''
                    }
                    data_testid="Select-498b3b66-87d4-43e8-bcbf-156418b4b0c2"
                  >
                    <MenuItem
                      value=""
                      onClick={() => {
                        const newQuery = { ...queries }
                        delete newQuery.sync_with_planning
                        isPagination
                          ? SetFilterQuery(newQuery, true)
                          : setQueries(prev => {
                              delete prev.sync_with_planning
                              return { ...prev }
                            })
                      }}
                      data-testid="MenuItem-e9e87232-7409-48c8-8349-11e1e32eb98f"
                    >
                      {dictionary.planning}
                    </MenuItem>
                    {isPlanning?.map(item => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        onClick={() => item.handleClick()}
                        data-testid="MenuItem-26fd67d7-f3da-43d7-82f7-a9daadf280ba"
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}

              {cost_status && (
                <Grid item xs={12}>
                  <Select
                    fullWidth
                    name={dictionary.status}
                    label={dictionary.status}
                    value={queries.status || ''}
                    data_testid="Select-61c3a847-73d0-4f48-888d-1080e68c3c49"
                  >
                    {costStatus(dictionary, setQueries)?.map(item => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        onClick={() => item.handleClick()}
                        data-testid="MenuItem-b2d591a4-c85c-4d24-8a46-a85f2c6e5c77"
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}

              {is_open && (
                <Grid item xs={12}>
                  <Select
                    name={dictionary.is_open}
                    fullWidth
                    label={dictionary.is_open}
                    value={
                      queries.is_open ? 7 : queries.is_open === false ? 8 : ''
                    }
                    data_testid="Select-aba58430-1fdc-4b3b-9bf0-0f93782016d1"
                  >
                    <MenuItem
                      value=""
                      onClick={() => {
                        const newQuery = { ...queries }
                        delete newQuery.is_open
                        isPagination
                          ? SetFilterQuery(newQuery, true)
                          : setQueries(prev => {
                              delete prev.is_open
                              return { ...is_open }
                            })
                      }}
                      data-testid="MenuItem-7d9f8827-cd76-4067-8723-0a6c0e40c1f0"
                    >
                      {dictionary.is_open}
                    </MenuItem>
                    {isOpen?.map(item => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        onClick={() => item.handleClick()}
                        data-testid="MenuItem-866ab077-de35-4d88-b4fd-124e39397171"
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}

              {is_internal && (
                <Grid item xs={12}>
                  <Select
                    fullWidth
                    name={dictionary.type}
                    label={dictionary.type}
                    value={
                      queries.is_internal === false
                        ? 1
                        : queries.is_internal
                        ? 2
                        : ''
                    }
                    data_testid="Select-e6349391-7b38-47c7-a645-d796ce2ebb84"
                  >
                    {isInternal?.map(item => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        onClick={() => item.handleClick()}
                        data-testid="MenuItem-3d307313-11e7-464a-b367-b63f671bbb2e"
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}

              {user_state && (
                <Grid item xs={12}>
                  <Select
                    fullWidth
                    name={dictionary.state}
                    label={dictionary.state}
                    value={queries.all_users ? '' : queries.archived ? 2 : 1}
                    data_testid="Select-06a4baaa-1b38-4a2b-bf7a-f6a1e64f7183"
                  >
                    {userState?.map(item => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        onClick={() => item.handleClick()}
                        data-testid="MenuItem-85b8367b-eccf-4fd3-bbb6-6186cb7457a3"
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}

              {tasks_status && (
                <Grid item xs={12}>
                  <Select
                    fullWidth
                    name={dictionary.status}
                    label={dictionary.status}
                    value={queries.is_logged}
                    data_testid="Select-fe167982-42c4-4cf4-973c-a11284adfede"
                  >
                    {tasksStatus?.map(item => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        onClick={() => item.handleClick()}
                        data-testid="MenuItem-b0d1eaf1-4c79-4b29-8e9b-ed39638e5f2f"
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}

              {tasks_states && (
                <Grid item xs={12}>
                  <Select
                    fullWidth
                    name={dictionary.state}
                    label={dictionary.state}
                    value={queries.is_completed}
                    data_testid="Select-fe167982-42c4-4cf4-973c-a11284adfede"
                  >
                    {tasksState?.map(item => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        onClick={() => item.handleClick()}
                        data-testid="MenuItem-b0d1eaf1-4c79-4b29-8e9b-ed39638e5f2f"
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}

              {user_status && (
                <Grid item xs={12}>
                  <Select
                    fullWidth
                    name={dictionary.status}
                    label={dictionary.status}
                    value={queries.status || ''}
                    data_testid="Select-fe167982-42c4-4cf4-973c-a11284adfede"
                  >
                    {userStatus?.map(item => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        onClick={() => item.handleClick()}
                        data-testid="MenuItem-b0d1eaf1-4c79-4b29-8e9b-ed39638e5f2f"
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}

              {billable && (
                <Grid item xs={12}>
                  <Select
                    fullWidth
                    name={dictionary.billable}
                    label={`${dictionary.billable}?`}
                    value={
                      queries.is_billable !== true &&
                      queries.is_billable !== false
                        ? 'all'
                        : queries.is_billable
                    }
                    data_testid="Select-ed2ed42b-d055-4d5e-b900-80f42edaa54a"
                  >
                    {billableList?.map(item => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        onClick={() => item.handleClick()}
                        data-testid="MenuItem-d2f2e79b-2ef3-4ebe-945d-1b12d9e0effb"
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}

              {reimbursable && (
                <Grid item xs={12}>
                  <Select
                    fullWidth
                    name={dictionary.reimbursable}
                    label={`${dictionary.reimbursable}?`}
                    value={
                      queries.is_payable !== true &&
                      queries.is_payable !== false
                        ? 'all'
                        : queries.is_payable
                    }
                    data_testid="Select-2c594872-de14-449d-bde1-914ec9d08b8d"
                  >
                    {reimbursableList?.map(item => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        onClick={() => item.handleClick()}
                        data-testid="MenuItem-a53b0e28-a893-4818-9a09-7279188dfd37"
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}

              {expenseCategories && (
                <Grid item xs={12}>
                  <Select
                    fullWidth
                    name={dictionary.categories}
                    label={dictionary.categories}
                    value={queries.category ? queries.category : ''}
                    data_testid="Select-0b210d16-f57d-4693-86b1-ea07fb82aac7"
                  >
                    <MenuItem
                      value=""
                      onClick={() => {
                        const newQuery = { ...queries }
                        delete newQuery.category
                        isPagination
                          ? SetFilterQuery(newQuery, true)
                          : setQueries(prev => {
                              delete prev.category
                              return { ...prev }
                            })
                      }}
                      data-testid="MenuItem-03cabef8-53ef-4b31-8278-604ed1dcd542"
                    >
                      {dictionary.all_categories}
                    </MenuItem>
                    {expenseCategoriesList?.map(item => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        onClick={() => item.handleClick()}
                        data-testid="MenuItem-c5ab88a9-6689-4e88-ac77-ae63ca134c7b"
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}

              {suppliers && (
                <Grid item xs={12}>
                  <Select
                    fullWidth
                    name={dictionary.suppliers}
                    label={dictionary.suppliers}
                    value={queries.supplier ? queries.supplier : ''}
                    data_testid="Select-b034e3de-7369-4980-a56c-1805eb80fe44"
                  >
                    <MenuItem
                      value=""
                      onClick={() => {
                        const newQuery = { ...queries }
                        delete newQuery.supplier
                        isPagination
                          ? SetFilterQuery(newQuery, true)
                          : setQueries(prev => {
                              delete prev.supplier
                              return { ...prev }
                            })
                      }}
                      data-testid="MenuItem-d8e668b0-400b-491b-9198-592caaff0780"
                    >
                      {dictionary.all_suppliers}
                    </MenuItem>
                    {expenseSuppliersList?.map(item => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        onClick={() => item.handleClick()}
                        data-testid="MenuItem-6e0b07cb-3fa8-41fb-b7b5-d4778c254662"
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}

              {stage && (
                <Grid item xs={12}>
                  <Select
                    fullWidth
                    name={dictionary.stage}
                    label={dictionary.stage}
                    value={queries.stage || ''}
                    data_testid="Select-13bc45e1-07c5-4f6a-9900-2011b8127ffa"
                  >
                    <MenuItem
                      value=""
                      onClick={() => {
                        const newQuery = { ...queries }
                        delete newQuery.stage
                        isPagination
                          ? SetFilterQuery(newQuery, true)
                          : setQueries(prev => {
                              delete prev.stage
                              return { ...prev }
                            })
                      }}
                      data-testid="MenuItem-0d8a8ec3-a96b-4713-bcd0-0556a8ababdc"
                    >
                      {dictionary.all_stages}
                    </MenuItem>
                    {stagesList?.map(item => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        onClick={() => item.handleClick()}
                        data-testid="MenuItem-182e4bf4-4ce4-4134-88d8-9122dd664eba"
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}

              {date && (
                <Grid item xs={12}>
                  <Select
                    fullWidth
                    name={dictionary.date}
                    label={dictionary.date}
                    value={
                      queries.start_date || queries.invoice_date_start
                        ? dateValue
                        : ''
                    }
                    data_testid="Select-6b739ede-e411-43a2-a712-ca231f76d73c"
                  >
                    {(invoice
                      ? invoiceDateFilter(
                          dictionary,
                          isPagination ? SetFilterQuery : setQueries,
                          isPagination,
                          queries
                        )
                      : dateFilter(
                          dictionary,
                          isPagination ? SetFilterQuery : setQueries,
                          isPagination,
                          queries,
                          finance
                        )
                    )?.map(item => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        onClick={() => {
                          if (item.value === 'custom_dates') {
                            setDateValue(item.value)
                            setOpenDatePicker(true)
                          } else {
                            setOpenDatePicker(false)
                            setDateValue(item.value)
                            item.handleClick()
                          }
                        }}
                        data-testid="MenuItem-1abc78a1-05da-45c1-95ef-7b28ee1e95ed"
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}

              {expenseStatus && (
                <Grid item xs={12}>
                  <Select
                    fullWidth
                    name={dictionary.status}
                    label={dictionary.status}
                    value={queries?.status || ''}
                    data_testid="Select-40ac7e35-f2cc-48ba-aeab-ff1e96c58d98"
                  >
                    {expenseStatusList?.map(item => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        onClick={() => item.handleClick()}
                        data-testid="MenuItem-895e7537-d7df-428f-a42f-54eede576925"
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}

              {invoice_open_status && (
                <Grid item xs={12}>
                  <Select
                    fullWidth
                    name={dictionary.status}
                    label={dictionary.status}
                    value={queries?.status || []}
                    multiple
                    data_testid="Select-e2616eb4-d488-43ee-bba0-2a518a8ee637"
                  >
                    {invoiceOpenStatus(
                      dictionary,
                      setQueries,
                      SetFilterQuery,
                      isPagination
                    )?.map(item => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        onClick={() => item.handleClick()}
                        data-testid="MenuItem-fbd04794-024f-460d-82dc-e657a3e76cc8"
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}

              {lease && (
                <Grid item xs={12}>
                  <Select
                    name={dictionary.lease}
                    fullWidth
                    label={dictionary.lease}
                    value={queries.lease || ''}
                    data_testid="Select-e9da5f51-06e1-46f0-af4a-ca030b56f21b"
                  >
                    <MenuItem
                      value=""
                      onClick={() => {
                        const newQuery = { ...queries }
                        delete newQuery.lease
                        isPagination
                          ? SetFilterQuery(newQuery, true)
                          : setQueries(prev => {
                              delete prev.lease
                              return { ...prev }
                            })
                      }}
                      data-testid="MenuItem-12222379-5416-4958-8488-c8f3c6da22e1"
                    >
                      {dictionary.all_leases}
                    </MenuItem>
                    {leaseList?.map(item => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        onClick={() => {
                          isPagination
                            ? SetFilterQuery({ lease: item.value })
                            : setQueries(prev => {
                                return { ...prev, lease: item.value }
                              })
                        }}
                        data-testid="MenuItem-74469aa9-3e1d-468b-b454-626bb694b5df"
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}

              {building && (
                <Grid item xs={12}>
                  <Select
                    name={dictionary.buildings}
                    fullWidth
                    label={dictionary.buildings}
                    value={queries.building || ''}
                    data_testid="Select-fd4306ae-969b-4cf9-9f88-79aefbc1bf49"
                  >
                    <MenuItem
                      value=""
                      onClick={() => {
                        const newQuery = { ...queries }
                        delete newQuery.building
                        isPagination
                          ? SetFilterQuery(newQuery, true)
                          : setQueries(prev => {
                              delete prev.building
                              return { ...prev }
                            })
                      }}
                      data-testid="MenuItem-0bdce5d1-329a-44e2-83d0-f69d2bba9e83"
                    >
                      {dictionary.all_buildings}
                    </MenuItem>
                    {buildingsList?.map(item => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        onClick={() => {
                          isPagination
                            ? SetFilterQuery({ building: item.value })
                            : setQueries(prev => {
                                return { ...prev, building: item.value }
                              })
                        }}
                        data-testid="MenuItem-16dfbbc2-8493-4afa-9629-d9176ef5dd43"
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}

              {floor && currentTab !== dictionary.floors && (
                <Grid item xs={12}>
                  <Select
                    name={dictionary.floors}
                    fullWidth
                    label={dictionary.floors}
                    value={queries.floor || ''}
                    data_testid="Select-58869ff1-b4f8-4b85-a5d8-4a67caaed664"
                  >
                    <MenuItem
                      value=""
                      onClick={() => {
                        const newQuery = { ...queries }
                        delete newQuery.floor
                        isPagination
                          ? SetFilterQuery(newQuery, true)
                          : setQueries(prev => {
                              delete prev.floor
                              return { ...prev }
                            })
                      }}
                      data-testid="MenuItem-ca33b5e8-9d99-4ed0-a56b-16257386be59"
                    >
                      {dictionary.all_floors}
                    </MenuItem>
                    {floorsList?.map(item => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        onClick={() => {
                          isPagination
                            ? SetFilterQuery({ floor: item.value })
                            : setQueries(prev => {
                                return { ...prev, floor: item.value }
                              })
                        }}
                        data-testid="MenuItem-381f0122-67f8-4198-a951-94fb8f8d3085"
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}

              {accounts && (
                <Grid item xs={12}>
                  <Select
                    name={dictionary.account}
                    fullWidth
                    label={dictionary.account}
                    value={queries.account || ''}
                    data_testid="Select-84ae158c-2b50-4e35-b7d3-17446515d4ca"
                  >
                    <MenuItem
                      value=""
                      onClick={() => {
                        const newQuery = { ...queries }
                        delete newQuery.account
                        isPagination
                          ? SetFilterQuery(newQuery, true)
                          : setQueries(prev => {
                              delete prev.account
                              return { ...prev }
                            })
                      }}
                      data-testid="MenuItem-838e9301-60f2-4fb1-919f-adb26a7e8c73"
                    >
                      {dictionary.all_accounts}
                    </MenuItem>
                    {accountsList?.map(item => (
                      <MenuItem
                        key={item.id}
                        value={item.id}
                        onClick={() => {
                          isPagination
                            ? SetFilterQuery({ account: item.id })
                            : setQueries(prev => {
                                return { ...prev, account: item.id }
                              })
                        }}
                        data-testid="MenuItem-1e16a76f-4ee0-4e17-97e1-8fd069cc2987"
                      >
                        {item.display}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}

              {/* 
                     {!!years.length && (
                     <Grid item xs={12}>
                      <Select
                        fullWidth
                        name="years"
                        label={dictionary.years}
                        value={queries.year || ''}
                      >
                        {[dictionary.years, ...years].map(item => (
                          <MenuItem
                            key={item + '' + Math.random()}
                            value={item}
                            onClick={() => {
                              if (item === dictionary.years) {
                                setQueries(prev => {
                                  delete prev.year
                                  return { ...prev }
                                })
                              } else {
                                setQueries(prev => {
                                  return { ...prev, year: item }
                                })
                              }
                            }}
                          >
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                     </Grid>
                     )} */}

              {mortgageType && (
                <Grid item xs={12}>
                  <Select
                    name={'type'}
                    fullWidth
                    label={dictionary.type}
                    value={queries.type}
                    data_testid="Select-577ea67b-9f15-4d20-b160-f91dc8100e0f"
                  >
                    {[
                      {
                        label: dictionary.all,
                        value: ''
                      },
                      {
                        label: dictionary.mortgages.principal_plus_interest,
                        value: 0
                      },
                      {
                        label: dictionary.mortgages.interest,
                        value: 1
                      }
                    ]?.map((item, idx) => (
                      <MenuItem
                        key={idx}
                        value={item.value}
                        onClick={() => {
                          isPagination
                            ? SetFilterQuery({ type: item.value })
                            : setQueries(prev => {
                                return { ...prev, type: item.value }
                              })
                        }}
                        data-testid="MenuItem-24449f44-2dbe-4f14-b316-8f51761d64f5"
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}

              {active && (
                <Grid item xs={12}>
                  <Select
                    name={'is_active'}
                    fullWidth
                    label={dictionary.status}
                    value={queries.is_active}
                    data_testid="Select-068937b0-efcc-41c6-94c5-35853a8b858c"
                  >
                    {[
                      { label: dictionary.all, value: '' },
                      { label: dictionary.active_m, value: 1 },
                      { label: dictionary.inactive_m, value: 0 }
                    ]?.map((item, idx) => (
                      <MenuItem
                        key={idx}
                        value={item.value}
                        onClick={() => {
                          isPagination
                            ? SetFilterQuery({ is_active: item.value })
                            : setQueries(prev => {
                                return { ...prev, is_active: item.value }
                              })
                        }}
                        data-testid="MenuItem-8846da16-b192-4b25-81b2-939b2b131e5b"
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}

              {priority && (
                <Grid item xs={12}>
                  <Select
                    name={dictionary.priority}
                    fullWidth
                    label={dictionary.priority}
                    value={queries.priority || ''}
                    data_testid="Select-88e1aba0-f6e8-4f29-b43a-13e84ef527cc"
                  >
                    <MenuItem
                      value=""
                      onClick={() => {
                        const newQuery = { ...queries }
                        delete newQuery.priority
                        isPagination
                          ? SetFilterQuery(newQuery, true)
                          : setQueries(prev => {
                              delete prev.priority
                              return { ...prev }
                            })
                      }}
                      data-testid="MenuItem-db02a010-caab-4e43-822a-0d21642463cc"
                    >
                      {dictionary.all_priorities}
                    </MenuItem>
                    {[
                      { label: dictionary.urgent, value: '0urgent' },
                      { label: dictionary.high, value: '1high' },
                      { label: dictionary.medium, value: '2medium' },
                      { label: dictionary.low, value: '3low' }
                    ]?.map((item, idx) => (
                      <MenuItem
                        key={idx}
                        value={item.value}
                        onClick={() => {
                          isPagination
                            ? SetFilterQuery({ priority: item.value })
                            : setQueries(prev => {
                                return { ...prev, priority: item.value }
                              })
                        }}
                        data-testid="MenuItem-b25435c4-8ad8-4359-a29c-4774877e468b"
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}

              {status && (
                <Grid item xs={12}>
                  <Select
                    name={dictionary.all_statuses}
                    fullWidth
                    label={dictionary.all_statuses}
                    value={queries.status || ''}
                    data_testid="Select-8457c04b-5fdf-423e-839d-36cff8e2773e"
                  >
                    <MenuItem
                      value=""
                      onClick={() => {
                        const newQuery = { ...queries }
                        delete newQuery.status
                        isPagination
                          ? SetFilterQuery(newQuery, true)
                          : setQueries(prev => {
                              delete prev.status
                              return { ...prev }
                            })
                      }}
                      data-testid="MenuItem-9329fa85-7608-457e-b26c-c40933a399cf"
                    >
                      {dictionary.all_statuses}
                    </MenuItem>
                    {[
                      { label: t('ticketing_status_opened'), value: 'opened' },
                      {
                        label: t('ticketing_status_reopened'),
                        value: 'reopened'
                      },
                      {
                        label: t('ticketing_status_resolved'),
                        value: 'resolved'
                      },
                      { label: t('ticketing_status_closed'), value: 'closed' },
                      {
                        label: t('ticketing_status_duplicate'),
                        value: 'duplicate'
                      }
                    ]?.map((item, idx) => (
                      <MenuItem
                        key={idx}
                        value={item.value}
                        onClick={() => {
                          isPagination
                            ? SetFilterQuery({ status: item.value })
                            : setQueries(prev => {
                                return { ...prev, status: item.value }
                              })
                        }}
                        data-testid="MenuItem-b7b606af-126c-49b9-97f8-58941e68704f"
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}

              {occupancy && currentTab === dictionary.lots && (
                <Grid item xs={12}>
                  <Select
                    name={t('occupancy')}
                    fullWidth
                    label={t('occupancy')}
                    value={queries.is_vacant || ''}
                  >
                    <MenuItem
                      value=""
                      onClick={() => {
                        const newQuery = { ...queries }
                        delete newQuery.is_vacant
                        isPagination
                          ? SetFilterQuery(newQuery, true)
                          : setQueries(prev => {
                              delete prev.is_vacant
                              return { ...prev }
                            })
                      }}
                    >
                      {t('all')}
                    </MenuItem>
                    {[
                      { label: t('occupied'), value: false },
                      { label: t('vacant'), value: true }
                    ]?.map((item, idx) => (
                      <MenuItem
                        key={idx}
                        value={item.value}
                        onClick={() => {
                          isPagination
                            ? SetFilterQuery({ is_vacant: item.value })
                            : setQueries(prev => {
                                return { ...prev, is_vacant: item.value }
                              })
                        }}
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}

              {tenant && (
                <Grid item xs={12}>
                  <Select
                    name={dictionary.all_tenants}
                    fullWidth
                    label={dictionary.all_tenants}
                    value={queries.tenant || ''}
                    data_testid="Select-761e094e-5b63-4cd2-a557-1b1817bf5376"
                  >
                    <MenuItem
                      value=""
                      onClick={() => {
                        const newQuery = { ...queries }
                        delete newQuery.tenant
                        isPagination
                          ? SetFilterQuery(newQuery, true)
                          : setQueries(prev => {
                              delete prev.tenant
                              return { ...prev }
                            })
                      }}
                      data-testid="MenuItem-f53a531f-5a57-400b-ab2d-10e491f5bf17"
                    >
                      {dictionary.all_tenants}
                    </MenuItem>
                    {tenantsList?.map(item => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        onClick={() => {
                          isPagination
                            ? SetFilterQuery({ tenant: item.value })
                            : setQueries(prev => {
                                return { ...prev, tenant: item.value }
                              })
                        }}
                        data-testid="MenuItem-a0603fc8-e8c4-41f7-899a-2e368432d31e"
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}

              {landlord && (
                <Grid item xs={12}>
                  <Select
                    name={dictionary.landlords.all_landlords}
                    fullWidth
                    label={dictionary.landlords.all_landlords}
                    value={queries.landlord || ''}
                    data_testid="Select-661c78dd-d060-4254-b0d1-6caf2d9a1e0a"
                  >
                    <MenuItem
                      value=""
                      onClick={() => {
                        const newQuery = { ...queries }
                        delete newQuery.landlord
                        isPagination
                          ? SetFilterQuery(newQuery, true)
                          : setQueries(prev => {
                              delete prev.landlord
                              return { ...prev }
                            })
                      }}
                      data-testid="MenuItem-59482f19-aac8-4bd7-8ec6-0aacc90cf5fb"
                    >
                      {dictionary.landlords.all_landlords}
                    </MenuItem>
                    {landlordsList?.map(item => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        onClick={() => {
                          isPagination
                            ? SetFilterQuery({ landlord: item.value })
                            : setQueries(prev => {
                                return { ...prev, landlord: item.value }
                              })
                        }}
                        data-testid="MenuItem-019e1b6f-9a94-4929-9118-7d7fd926074f"
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}
            </Grid>
            {openDatePicker && (
              <Grid item xs={6} p={4}>
                <form
                  data-cy={'form'}
                  onSubmit={handleSubmit(onSubmitCustomDates)}
                  noValidate
                >
                  <Stack sx={{ height: '100%' }} spacing={3}>
                    <Typography
                      variant="h6"
                      color={'text.light'}
                      mb={2}
                      data-testid="Typography-29669afb-273b-4d5b-ad03-3ee49de29ed2"
                    >
                      {dictionary.custom_dates}
                    </Typography>

                    <DatePicker
                      required
                      name="start_date"
                      label={dictionary.start_date}
                      variant="outlined"
                      fullWidth
                      control={control}
                      error={Boolean(errors.start_date)}
                      errorMessage={errors.start_date?.message}
                      data_testid="DatePicker-9479ab00-97a8-46e3-8ae3-78255bbf4db3"
                    />

                    <DatePicker
                      required
                      name="end_date"
                      label={dictionary.end_date}
                      variant="outlined"
                      fullWidth
                      control={control}
                      error={Boolean(errors.end_date)}
                      errorMessage={errors.end_date?.message}
                      data_testid="DatePicker-b030607d-eee3-4ac6-b40f-a19c69c23900"
                    />

                    <Stack direction="row" spacing={2}>
                      <Button
                        type="submit"
                        data-testid="Button-35067014-dd3a-4e5e-af56-c0d67e6fa770"
                      >
                        {dictionary.submit}
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => setOpenDatePicker(false)}
                        data-testid="Button-99e27a7b-42ce-4079-bae0-d5aea1585762"
                      >
                        {dictionary.close}
                      </Button>
                    </Stack>
                  </Stack>
                </form>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Popover>
    </>
  )
}

export default GeneralFilter

GeneralFilter.propTypes = {
  dictionary: PropTypes.object,
  queries: PropTypes.object,
  setQueries: PropTypes.func,
  isPagination: PropTypes.bool,
  SetFilterQuery: PropTypes.func,
  is_open: PropTypes.bool,
  is_internal: PropTypes.bool,
  user_state: PropTypes.bool,
  tasks_status: PropTypes.bool,
  tasks_states: PropTypes.bool,
  user_status: PropTypes.bool,
  billable: PropTypes.bool,
  reimbursable: PropTypes.bool,
  expenseCategories: PropTypes.bool,
  suppliers: PropTypes.bool,
  stage: PropTypes.bool,
  date: PropTypes.bool,
  expenseStatus: PropTypes.bool,
  invoice_open_status: PropTypes.bool,
  lease: PropTypes.bool,
  building: PropTypes.bool,
  floor: PropTypes.bool,
  accounts: PropTypes.bool,
  mortgageType: PropTypes.bool,
  active: PropTypes.bool,
  priority: PropTypes.bool,
  status: PropTypes.bool,
  tenant: PropTypes.bool,
  landlord: PropTypes.bool,
  height: PropTypes.number,
  currentTab: PropTypes.string,
  occupancy: PropTypes.bool
}

import React, { useState } from 'react'
import Link from 'next/link'

import {
  Popover,
  List,
  ListItemButton,
  ListItemIcon,
  Box,
  Typography,
  IconButton,
  Tooltip,
  CircularProgress
} from '@mui/material'

import { MoreHoriz } from '@mui/icons-material'

const ActionsPopover = ({
  actions,
  size = 'small',
  elementId,
  disabled = false,
  actionsDisabled = false,
  iconStyle = {}
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const handleClick = event => {
    event.stopPropagation()
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = event => {
    event?.stopPropagation()
    event?.preventDefault()
    setAnchorEl(null)
  }

  const onActionClick = async handleActionClick => {
    setIsLoading(true)
    handleActionClick && (await handleActionClick(elementId))
    setIsLoading(false)
    handleClose()
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <Box>
      <IconButton
        size={size}
        aria-describedby={id}
        onClick={handleClick}
        data-testid="IconButton-fd45aeb1-b085-44cf-83cf-c3aef303b443"
        disabled={actionsDisabled || isLoading}
      >
        {isLoading ? (
          <CircularProgress color="primary" size={14} />
        ) : (
          <MoreHoriz fontSize={size} sx={iconStyle} />
        )}
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right'
        }}
      >
        <List>
          {actions
            ?.filter(a => !a.hidden)
            .map(
              (
                { id, label, icon, handleClick, isDisabled, tooltip, link },
                index
              ) => {
                const getListItem = () => (
                  <ListItemButton
                    key={id}
                    onClick={e => {
                      e?.stopPropagation()
                      e?.preventDefault()
                      onActionClick(handleClick)
                    }}
                    disabled={isDisabled ? isDisabled : disabled}
                    data-testid="ListItemButton-02992f0e-122e-4297-9f96-a7825f0948b5"
                  >
                    {link ? (
                      <>
                        <ListItemIcon sx={{ minWidth: 'unset', mr: 1 }}>
                          {icon}
                        </ListItemIcon>
                        <Typography
                          data-testid={`Typography-cce4585b-5442-4dcd-a1ac-80c7c1cf55b0-${index}`}
                        >
                          <Link href={link} passHref>
                            {label}
                          </Link>
                        </Typography>
                      </>
                    ) : (
                      <>
                        <ListItemIcon sx={{ minWidth: 'unset', mr: 1 }}>
                          {icon}
                        </ListItemIcon>
                        <Typography
                          data-testid={`Typography-a42ac8f5-ac35-4b29-a7a0-1f3c73de1466-${index}`}
                        >
                          {label}
                        </Typography>
                      </>
                    )}
                  </ListItemButton>
                )

                if (tooltip) {
                  return (
                    <Tooltip title={tooltip} key={id}>
                      <span data-testid="span-71366e11-38cc-4a80-b918-d130f83957df">
                        {getListItem()}
                      </span>
                    </Tooltip>
                  )
                }

                return getListItem()
              }
            )}
        </List>
      </Popover>
    </Box>
  )
}

export default ActionsPopover
